import React, { useEffect } from 'react';
import { Layout, Row, Col} from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { setReportLoading, setReports, fetchGlobal } from './redux'
import Loader from './components/Loader';
import axios from 'axios'
import { apiBaseUrl } from './Url'
import Titledetails from './components/report-details/TitleDetails';
import DangerRatings from './components/override-outcome/DangerRatings';


const { Content } = Layout;

function OverrideOutcome({ setReports, setReportLoading, report, history, match, fetchGlobal, user }) {

    const img_path = "../images/";

    useEffect(() => {

        if (!user.details.type) {
            history.push('/login')
        }

    }, [user.details.type, history]);

    useEffect(() => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/get_view_data",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: match.params.id }
            })
            .then(response => {
                // console.log(response)
                if (response.data.status !== 0) {
                    setReports(response.data.reports)
                } else {
                    history.push('/reports')
                }
                setReportLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
                // showError(errorMsg)
            })

    }, [match.params.id, setReportLoading, setReports, history]);

    


    return (
        <div>
            <ContentHeader title={"Override the calculate outcome"} />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                        <Col md={5} sm={12}>
                        </Col>
                        {(report.reports.length > 0)
                            ?
                            <Col md={14} sm={12}>
                                <center>
                                    <div className="report-container" >
                                        <Titledetails
                                            region={report.reports[0].report.region}
                                            created_by={report.reports[0].report.created_by}
                                            published_by={report.reports[0].report.published_by}
                                            regional_outlook={report.reports[0].report.regional_outlook}
                                            date={report.reports[0].report.date}
                                            region_img={report.reports[0].report.region_img}
                                            img_path={img_path}
                                            forecast_confidence={report.reports[0].report.forecast_confidence}
                                        />
                                        <br />
                                        <DangerRatings
                                            alpine_hazards={report.reports[0].alpine_hazards}
                                            sub_alpine_hazards={report.reports[0].sub_alpine_hazards}
                                            override_alpine_rating={report.reports[0].report.override_alpine_rating}
                                            override_sub_alpine_rating={report.reports[0].report.override_sub_alpine_rating}
                                            travel_and_terrain_advice={report.reports[0].report.travel_and_terrain_advice}
                                            region={report.reports[0].report.region}
                                            img_path={img_path}
                                            date={report.reports[0].report.date}
                                            fetchGlobal={fetchGlobal}
                                            setReportLoading={setReportLoading}
                                            history={history}
                                            match={match}
                                        />

                                    </div>
                                </center>
                            </Col>
                            :
                            null
                        }
                    </Row>
                </div>
                {(report.loading ? <Loader title="Loading..." /> : null)}
            </Content>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        report: state.report,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportLoading: (bool) => dispatch(setReportLoading(bool)),
        setReports: (obj) => dispatch(setReports(obj)),
        fetchGlobal: () => dispatch(fetchGlobal()),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OverrideOutcome);
