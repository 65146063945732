import React, { useEffect, useState } from 'react';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux'
import { setReportLoading, setReports } from './redux'
import axios from 'axios'
import { apiBaseUrl } from './Url'
import Loader from './components/Loader';
import RangeImg from './components/report-summery-details/RangeImg';
import moment from 'moment'
import './embedd.css';

const { Content } = Layout;

function ViewSummerizedEmbeddedReportLarge({ setReportLoading, setReports, report, match, history }) {
    const [mainRage, setMainRange] = useState([])
    const [devidingRange, setDevidingEange] = useState([])
    const [frontRange, setFrontRange] = useState([])
    const [regions, setRegions] = useState([{ region: 'Front Range', spring_condition: 0 }, { region: 'Dividing Range', spring_condition: 0 }, { region: 'Main range', spring_condition: 0 }])

    useEffect(() => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "embedd/get_view_data_by_date",
                data: { date: match.params.id ? match.params.id : moment(new Date()).format("YYYY-MM-DD") }
            })
            .then(response => {
                console.log(response)
                setRegions(response.data.regions)
                if (response.data.status !== 0) {
                    setReports(response.data.reports)
                } else {
                    setReports([])
                }
                setReportLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
                // showError(errorMsg)
            })

    }, [setReportLoading, setReports, match.params.id]);


    useEffect(() => {
        let result = report.reports.filter(t => t.report.region === 'Main range');
        setMainRange(result)

        result = report.reports.filter(t => t.report.region === 'Dividing Range');
        setDevidingEange(result)

        result = report.reports.filter(t => t.report.region === 'Front Range');
        setFrontRange(result)
    }, [report.reports]);


    var sectionStyle = {
        backgroundImage: "url(../images/summery-container-large.png)",
        backgroundColor: "#f2f2f2",
        minHeight: "200vh",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat"
    };


    const openUrl = (e, region) => {
        // const date = (match.params.id) ? moment(new Date(match.params.id)).format('YYYY-MM-DD') : moment(new Date()).format("YYYY-MM-DD");
        var url = ""
        if (region === "Main Range") {
            // window.location.replace('/main-range/' + date)
            // url = '/main-range/' + date
            url = '/main-range'
            window.open(url, '_blank')
        }

        if (region === "Dividing Range") {
            // url = '/dividing-range/' + date
            url = '/dividing-range'
            window.open(url, '_blank')
        }

        if (region === "Front Range") {
            // url = '/front-range/' + date
            url = '/front-range'
            window.open(url, '_blank')
        }
    }

    // const openArchive = (e, region, date) => {
    //     // const date = (match.params.id) ? moment(new Date(match.params.id)).format('YYYY-MM-DD') : moment(new Date()).format("YYYY-MM-DD");
    //     // console.log(d);
    //     var url = ""
    //     if (region === "Main Range") {
    //         url = '/main-range/' + date
    //         window.open(url, '_blank')
    //     }

    //     if (region === "Dividing Range") {
    //         url = '/dividing-range/' + date
    //         window.open(url, '_blank')
    //     }

    //     if (region === "Front Range") {
    //         url = '/front-range/' + date
    //         window.open(url, '_blank')
    //     }
    // }


    const openSpringUrl = (e, region) => {
        // const date = (match.params.id) ? moment(new Date(match.params.id)).format('YYYY-MM-DD') : moment(new Date()).format("YYYY-MM-DD");
        var url = ""
        if (region === "Main Range") {
            // window.location.replace('/main-range/' + date)
            // url = '/main-range/' + date
            url = '/view-spring-condition-report/main-range'
            window.open(url, '_blank')
        }

        if (region === "Dividing Range") {
            // url = '/dividing-range/' + date
            url = '/view-spring-condition-report/dividing-range'
            window.open(url, '_blank')
        }

        if (region === "Front Range") {
            // url = '/front-range/' + date
            url = '/view-spring-condition-report/front-range'
            window.open(url, '_blank')
        }
    }

    console.log("main", mainRage);
    return (
        <div>
            {/* <ContentHeader title="MSC HQ" /> */}
            <Content style={{ margin: '0px 0px 0' }} >
                <Row style={{ backgroundColor: "#f2f2f2" }}>
                    <Col sm={24} xs={24} className="summery-embedded-report-wrapper-large" style={sectionStyle}>
                        <Row className="details">
                            <Col sm={24} className="header">
                               
                            </Col>
                            <Col sm={24} xs={24} className="ranges" onClick={(regions[2].spring_condition === 1) ? e => openSpringUrl(e, "Main Range") : e => openUrl(e, "Main Range")}>
                                <div className="main-range">

                                    {
                                        (regions[2].spring_condition === 1) ?
                                            <div onClick={e => openSpringUrl(e, "Main Range")}>
                                                <img src="../images/surface-condtion-active1.png" alt="summery_101" />
                                            </div>
                                            :
                                            (mainRage.length > 0)
                                                ?
                                                mainRage.map(function (item, i) {
                                                    return (
                                                        <div key={i} onClick={e => openUrl(e, "Main Range")}>
                                                            <RangeImg
                                                                alpine_hazards={item.alpine_hazards}
                                                                sub_alpine_hazards={item.sub_alpine_hazards}
                                                                region={item.report.region}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div >
                                                    <img src="../images/summary_10.png" alt="summery_10" />
                                                </div>
                                    }
                                </div>
                            </Col>

                            <Col sm={24} xs={24} className="ranges" onClick={(regions[1].spring_condition === 1) ? e => openSpringUrl(e, "Dividing Range") : e => openUrl(e, "Dividing Range")}>
                                <div className="deviding-range">
                                    {
                                        (regions[1].spring_condition === 1) ?
                                            <div onClick={e => openSpringUrl(e, "Dividing Range")}>
                                                <img src="../images/surface-condtion-active1.png" alt="summery_101" />
                                            </div>
                                            :

                                            (devidingRange.length > 0)
                                                ?
                                                devidingRange.map(function (item, i) {
                                                    return (
                                                        <div key={i} onClick={e => openUrl(e, "Dividing Range")}>
                                                            <RangeImg
                                                                alpine_hazards={item.alpine_hazards}
                                                                sub_alpine_hazards={item.sub_alpine_hazards}
                                                                region={item.report.region}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div >
                                                    <img src="../images/summary_10.png" alt="summery_10" />
                                                </div>
                                    }
                                </div>
                            </Col>



                            <Col sm={24} xs={24} className="ranges" onClick={(regions[0].spring_condition === 1) ? e => openSpringUrl(e, "Front Range") : e => openUrl(e, "Front Range")}>
                                <div className="front-range">
                                    {
                                        (regions[0].spring_condition === 1) ?
                                            <div onClick={e => openSpringUrl(e, "Front Range")}>
                                                <img src="../images/surface-condtion-active1.png" alt="summery_101" />
                                            </div>
                                            :

                                            (frontRange.length > 0)
                                                ?
                                                frontRange.map(function (item, i) {
                                                    return (
                                                        <div key={i} onClick={e => openUrl(e, "Front Range")}>
                                                            <RangeImg
                                                                alpine_hazards={item.alpine_hazards}
                                                                sub_alpine_hazards={item.sub_alpine_hazards}
                                                                region={item.report.region}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div >
                                                    <img src="../images/summary_10.png" alt="summery_10" />
                                                </div>
                                    }
                                </div>
                            </Col>


                            <Col sm={24} xs={24} className="ranges">
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
            {(report.loading ? <Loader title="Loading..." /> : null)}
        </div>
    );
}




const mapStateToProps = state => {
    return {
        report: state.report,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportLoading: (bool) => dispatch(setReportLoading(bool)),
        setReports: (obj) => dispatch(setReports(obj)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewSummerizedEmbeddedReportLarge);
