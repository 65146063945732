import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Modal } from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { fetchGlobal, createUserFaliur, setLogout, setUserLoading } from './redux'
import { Input, Button, Spin, message } from 'antd';

import axios from 'axios'
import { apiBaseUrl } from './Url'
const sha512 = require("js-sha512");

const { Content } = Layout;

function EditUser({ match, fetchGlobal, createUserFaliur, setLogout, setUserLoading, user, history, global }) {
    const [id, setId] = useState("")
    const [fname, setFname] = useState("")
    const [lname, seLname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [rePassword, setRePassword] = useState("")

    useEffect(() => {

        if (!user.details.email) {
            history.push("/login")
        }

    }, [user, history]);

    useEffect(() => {

        const array = global.users.filter(function (el) {
            return el.id == match.params.id;
        });

        setId(array[0].id)
        setFname(array[0].fname);
        seLname(array[0].lname);
        setEmail(array[0].email);
        setPhone(array[0].phone);

    }, [global, match.params.id])



    function handleLogin(e) {
        e.preventDefault();

        if (handleValidation()) {
            register();
        }
    }

    function handleValidation() {
        let formIsValid = true;

        if (!fname) {
            formIsValid = false;
            message.error('Please insert first name.');
            return formIsValid;
        }

        if (!lname) {
            formIsValid = false;
            message.error('Please insert last name.');
            return formIsValid;
        }


        //email
        if (!email) {
            formIsValid = false;
            message.error('Please insert email address.');
            return formIsValid;
        }

        if (typeof email !== "undefined") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                message.error("Email is not valid.");
                return formIsValid;
            }
        }

        return formIsValid;
    }


    function register() {
        // setUserLoading(true)
        const obj = { id, fname, lname, email, phone }
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/edit-basic-details-by-admin",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { obj }
            })
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    success()
                } else if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                }
                else {
                    showError(response.data.message)
                    createUserFaliur(response.data.message)
                }
            })
            .catch(error => {
                const errorMsg = error.message
                showError(errorMsg)
                createUserFaliur(errorMsg)
            })
    }



    function success() {
        Modal.success({
            content: 'User has been successfully updated.',
            onOk() { afterSuccess() },
        });
    }

    function afterSuccess() {
        fetchGlobal()
        history.push('/users')
    }

    function showError(msg) {
        Modal.error({
            content: msg,
        });
    }

    function handleLogin1(e) {
        e.preventDefault();

        if (handleValidation1()) {
            changePassword();
        }
    }

    function handleValidation1() {
        let formIsValid = true;

        //newPassword
        if (!newPassword) {
            formIsValid = false;
            message.error("Please insert your new password.");
            return formIsValid;
        }

        var lowerCaseLetters = /[a-z]/g;
        if (!newPassword.match(lowerCaseLetters)) {
            formIsValid = false;
            message.error("New password must include at least one lowercase letter");
            return formIsValid;
        }

        var upperCaseLetters = /[A-Z]/g;
        if (!newPassword.match(upperCaseLetters)) {
            formIsValid = false;
            message.error("New password must include at least one uppercase letter");
            return formIsValid;
        }

        var numbers = /[0-9]/g;
        if (!newPassword.match(numbers)) {
            formIsValid = false;
            message.error("New password must include at least one number.");
            return formIsValid;
        }

        if (newPassword.length < 8) {
            formIsValid = false;
            message.error("New password must be a minimum of 8 characters.");
            return formIsValid;
        }

        if (!rePassword) {
            formIsValid = false;
            message.error("Please re-type your password.");
            return formIsValid;
        }

        if (newPassword !== rePassword) {
            formIsValid = false;
            message.error("Confirm password field not match with new password field.");
            return formIsValid;
        }

        return formIsValid;
    }



    function changePassword() {
        // setUserLoading(true)
        const password = sha512(newPassword);
        const obj = { id, password}
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/edit-password-details-by-admin",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { obj }
            })
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    success()
                } else if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                }
                else {
                    showError(response.data.message)
                    createUserFaliur(response.data.message)
                }
            })
            .catch(error => {
                const errorMsg = error.message
                showError(errorMsg)
                createUserFaliur(errorMsg)
            })
    }

    return (
        <div>
            <ContentHeader title="Edit User " />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" >
                    <Row>
                        <Col md={2} sm={24}></Col>
                        <Col md={20} sm={24}>
                            <form onSubmit={(e) => handleLogin(e)}>
                                <Row>
                                    <Col md={24} sm={24} className="padding-5">
                                        <h3>Basic Details</h3>
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>First Name</label>
                                        <Input type="text" placeholder="First Name" onChange={(e) => setFname(e.target.value)} value={fname} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Last Name</label>
                                        <Input type="text" placeholder="Last Name" onChange={(e) => seLname(e.target.value)} value={lname} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Email Address</label>
                                        <Input type="text" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Phone Number</label>
                                        <Input type="text" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit">Edit</Button>
                                        {user.loading ? <>&nbsp;<Spin size="large" /> </> : null}
                                    </Col>
                                </Row>
                                <br />
                            </form>

                            <form onSubmit={(e) => handleLogin1(e)}>
                                <br />
                                <Row>
                                    <Col md={24} sm={24} className="padding-5">
                                        <h3>Password Details</h3>
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Password</label>
                                        <Input.Password className="text-area-" type="text" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Re-Type Password</label>
                                        <Input.Password className="text-area-" type="text" onChange={(e) => setRePassword(e.target.value)} value={rePassword} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit">Edit</Button>
                                        {user.loading ? <>&nbsp;<Spin size="large" /> </> : null}
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Col>
                        <Col md={2} sm={24}></Col>
                    </Row>
                </div>
            </Content>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        user: state.user,
        global: state.global
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGlobal: () => dispatch(fetchGlobal()),
        setUserLoading: (bool) => dispatch(setUserLoading(bool)),
        createUserFaliur: (msg) => dispatch(createUserFaliur(msg)),
        setLogout: () => dispatch(setLogout()),
        fetchGlobal: () => dispatch(fetchGlobal()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
