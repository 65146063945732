import React, { useEffect } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { fetchGlobal, setLogout } from './redux'
import Loader from './components/Loader';
import UsersList from './components/users/UsersList';
import { Link } from "react-router-dom";


const { Content } = Layout;

function Users({ global, fetchGlobal, setLogout, history, user }) {
    
    useEffect(() => {
        if (global.montainRanges.length === 0) {
            fetchGlobal()
        }
    }, [fetchGlobal, global.montainRanges.length])

    useEffect(() => {
        if (user.details.type !== "admin") {
            history.push('/')
        }
    }, [user.details.type, history])



    return (
        <div>
            <ContentHeader title="Users" />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                        <Col span={24}>
                            <Link to="/create-user"><Button type="primary"   >Create User</Button></Link>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col span={24}>
                            <UsersList data={global.users} fetchGlobal={fetchGlobal} setLogout={setLogout} />
                        </Col>
                    </Row>
                </div>
                {(global.loading ? <Loader title="Loading..." /> : null)}
            </Content>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        global: state.global,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGlobal: () => dispatch(fetchGlobal()),
        setLogout: (key) => dispatch(setLogout(key)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Users);
