import { combineReducers } from 'redux';
import userReducer from './user/userReducer'
import globalReducer from './global/globalReducer'
import newReportReducer from './new-report/newReportReducer'
import reportReducer from './report/reportReducer'

const rootReducer = combineReducers({
    user: userReducer,
    global: globalReducer,
    newReport: newReportReducer,
    report: reportReducer,
})

export default rootReducer