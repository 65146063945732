import React, { useEffect } from 'react';
import { Layout, Row, Col, DatePicker } from 'antd';
// import ContentHeader from './components/ContentHeader';
// import moment from 'moment'
import { connect } from 'react-redux'
import { setReportLoading, setReports } from './redux'
import axios from 'axios'
import { apiBaseUrl } from './Url'
import Loader from './components/Loader';
import Report from './components/Report';
import moment from 'moment'

const { Content } = Layout;

function ViewDividingRangeReport({ setReportLoading, setReports, report, match, history }) {

    useEffect(() => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/get_view_data_by_date_and_type",
                data: { date: match.params.id ? match.params.id : "", range: 2 }
            })
            .then(response => {
                // console.log(response)
                if (response.data.status !== 0) {

                    // if date comes from url show the report
                    if (!match.params.id) {

                        //if spring condtion activated redirect to spring condtion report
                        if (response.data.reports[0].report.spring_condition === 1) {
                            const url = '/view-spring-condition-report/dividing-range'
                            // window.open(url, '_blank')
                            window.location.replace(url)
                        } else {
                            setReports(response.data.reports)
                        }
                    } else {
                        setReports(response.data.reports)

                    }



                } else {
                    setReports([])
                }
                setReportLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
                // showError(errorMsg)
            })

    }, [setReportLoading, setReports, match.params.id]);




    var sectionStyle = {
        backgroundImage: "url(../images/report-background.png)",
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "center center",
        backgroundSize: "100%"
    };


    function onDateChange(e) {
        const date = moment(new Date(e)).format('YYYY-MM-DD')

        window.location.replace('/dividing-range/' + date)
    }


    return (
        <div>
            {/* <ContentHeader title="MSC HQ" /> */}
            <Content style={{ margin: '0px 0px 0' }} >
                <div className="site-layout-background reports-layout-background" style={{ padding: 0 }} >
                    <Row>



                        <Col md={6} sm={12} style={sectionStyle}>
                        </Col>


                        <Col md={12} sm={24}>

                            <Row>
                                <Col md={24} sm={24} >
                                    <center>
                                        <div className="report-container" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <a href="https://mountainsafetycollective.org/">
                                                <img alt="header" src="../images/header.png" className="report-header-img" />
                                            </a>
                                        </div>
                                    </center>
                                </Col>
                            </Row>

                            {(report.reports.length > 0)
                                ?
                                report.reports.map(function (item, i) {
                                    return (
                                        <Row key={i}>
                                            <Report item={item} />
                                        </Row>
                                    )
                                })
                                :
                                <Row >
                                    <Col md={24} sm={24} xs={24} className="no-report-wrapper" >
                                        <center>
                                            <br />
                                            <DatePicker onChange={e => onDateChange(e)}
                                                // value={moment(date, 'YYYY/MM/DD')}
                                                // defaultValue={moment(date, 'DD/MM/YYYY')} 
                                                format={'DD/MM/YYYY'} />

                                            {/* <p >No Report Found.</p> */}
                                        </center>
                                    </Col>
                                </Row>
                            }
                        </Col>

                        <Col md={6} sm={12} style={sectionStyle}>
                        </Col>
                    </Row>
                </div>
            </Content>
            {(report.loading ? <Loader title="Loading..." /> : null)}
        </div>
    );
}




const mapStateToProps = state => {
    return {
        report: state.report,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportLoading: (bool) => dispatch(setReportLoading(bool)),
        setReports: (obj) => dispatch(setReports(obj)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewDividingRangeReport);
