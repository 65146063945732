import React, { useState } from 'react'
import { Table, Checkbox, Tooltip, Select, Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import { apiBaseUrl } from '../../Url'
import Loader from '../Loader';
import { Link } from "react-router-dom";

const { confirm } = Modal;


const { Option } = Select;

function UsersList({ data, fetchGlobal, setLogout, history }) {
    const [loading, setLoading] = useState(false)

    const changeUserStatus = (e, id) => {
        // console.log(`checked = ${e.target.checked}`);
        setLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/change_status",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: id, status: e.target.checked }
            })
            .then(response => {
                if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                } else {
                    fetchGlobal()
                }
                setLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                console.log(errorMsg)
                setLoading(false)
            })
    }

    const changeType = (value, id) => {
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/change_type",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: id, type: value }
            })
            .then(response => {
                if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                } else {
                    fetchGlobal()
                }
                setLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                console.log(errorMsg)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'User Id',
            dataIndex: 'id',
            key: 'id',
            sorter: {
                compare: (a, b) => a.id - b.id
            },
        },
        {
            title: 'First Name',
            dataIndex: "fname"
        },
        {
            title: 'Last Name',
            dataIndex: "lname",
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Type',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div key={record.id}>
                    <Select
                        style={{ width: 150 }}
                        placeholder="Assign a type"
                        onChange={e => changeType(e, record.id)}
                        value={record.type}
                    >
                        <Option value="creator">Creator</Option>
                        <Option value="publisher">Publisher</Option>
                    </Select>
                </div>
            ),
        },
        {
            title: 'Active',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div key={record.id}>
                    <Tooltip title="Activate user" >
                        <Checkbox onChange={e => changeUserStatus(e, record.id)} checked={(record.status === "active") ? true : false}>Checkbox</Checkbox>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div key={record.id}>
                    <Tooltip title="Edit User" >
                        <Link to={"/edit-user/" + record.id}><Button type="primary" size="small" ><EditOutlined /></Button></Link>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Delete User" >
                        <Button onClick={e => deleteUserMsg(e, record.id)} type="primary" danger size="small"><DeleteOutlined /></Button>
                    </Tooltip>
                </div>
            ),
        },
    ];



    const deleteUserMsg = (e, id) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Are You Sure?",
            onOk() {
                deleteUser(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const deleteUser = (id) => {
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/delete",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id }
            })
            .then(response => {
                fetchGlobal()
                history.push('/users')
            })
            .catch(error => {
                const errorMsg = error.message
                console.log(errorMsg);
            })
    }

    return (
        <div>
            <Table columns={columns} dataSource={data} rowKey="id" />
            {(loading) ? <Loader title="Loading..." /> : null}
        </div>
    )
}

export default UsersList
