import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css'
import './index.css';
import { Layout } from 'antd';

import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';

import { Provider } from 'react-redux'
import store from "./redux/store";

import Sidebar from './components/SideBar';
import Footer from './components/FooterHQ';

import Login from './Login';
import Logout from './Logout';
import Register from './Register';

import Users from './Users';

import Home from './Home';
import CreateReport from './CreateReport';
import EditReport from './EditReport';
import Reports from './Reports';
import ViewReportByDate from './ViewReportByDate';
import EmbeddedCodePreiview from './EmbeddedCodePreiview';

import EditBasic from './components/user/EditBasic';
import EditPassword from './components/user/EditPassword';

import ViewAndPublishReport from './ViewAndPublishReport';
import OverrideOutcome from './OverrideOutcome';
import ViewMainRangeReport from './ViewMainRangeReport';
import ViewDividingRangeReport from './ViewDividingRangeReport';
import ViewFrontRangeReport from './ViewFrontRangeReport';

import ViewSummerizedEmbeddedReport from './ViewSummerizedEmbeddedReport';
import ViewSummerizedEmbeddedReportFrontAndDividing from './ViewSummerizedEmbeddedReportFrontAndDividing';
import ViewSummerizedEmbeddedReprtMainrange from './ViewSummerizedEmbeddedReprtMainrange';
import ViewSummerizedEmbeddedReportLarge from './ViewSummerizedEmbeddedReportLarge';
import ViewSummerizedTest from './ViewSummerizedTest';

import Test from './Test';
import Analytics from './Analytics';

import MountainRanges from './MountainRanges';
import Viewspringconditionsreport from './ViewSpringConditionsReport';
import CreateUser from './CreateUser';
import EditUser from './EditUser';




// console.log(useParams());
ReactDOM.render(
  <Router history={createBrowserHistory}>
    <Provider store={store}>
      <Layout>
        <Sidebar />

        <Layout>

          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/register" component={Register} />

          <Route path="/create-report" component={CreateReport} />
          <Route path="/edit-report/:id" component={EditReport} />
          <Route path="/view-and-publish-report/:id" component={ViewAndPublishReport} />
          <Route path="/override-coutcome/:id" component={OverrideOutcome} />
          <Route path="/reports" component={Reports} />
          <Route path="/view-embedded-report" component={EmbeddedCodePreiview} />

          <Route path="/view-spring-condition-report/:id" component={Viewspringconditionsreport} />

          <Route exact path="/report" component={ViewReportByDate} />
          <Route path="/report/:id" component={ViewReportByDate} />

          <Route  exact path="/main-range" component={ViewMainRangeReport} />
          <Route  path="/main-range/:id" component={ViewMainRangeReport} />

          <Route  exact path="/embedded-report-summary" component={ViewSummerizedEmbeddedReport} />
          <Route  path="/embedded-report-summary/:id" component={ViewSummerizedEmbeddedReport} />

          <Route  exact path="/embedded-report-summary-front-and-dividing-range" component={ViewSummerizedEmbeddedReportFrontAndDividing} />
          <Route  path="/embedded-report-summary-front-and-dividing-range/:id" component={ViewSummerizedEmbeddedReportFrontAndDividing} />

          <Route  exact path="/embedded-report-summary-large" component={ViewSummerizedTest} />
          <Route  path="/embedded-report-summary-large/:id" component={ViewSummerizedTest} />

          <Route  exact path="/embedded-report-summary-main-range" component={ViewSummerizedEmbeddedReprtMainrange} />
          <Route  path="/embedded-report-summary-main-range/:id" component={ViewSummerizedEmbeddedReprtMainrange} />
{/* 
          <Route  exact path="/embedded-report-summary-large" component={ViewSummerizedEmbeddedReportLarge} />
          <Route  path="/embedded-report-summary-large/:id" component={ViewSummerizedEmbeddedReportLarge} /> */}
          

          <Route  exact path="/dividing-range" component={ViewDividingRangeReport} />
          <Route  path="/dividing-range/:id" component={ViewDividingRangeReport} />

          <Route  exact path="/front-range" component={ViewFrontRangeReport} />
          <Route  path="/front-range/:id" component={ViewFrontRangeReport} />

          <Route path="/users" component={Users} />

          <Route path="/edit-user-basic-details" component={EditBasic} />
          <Route path="/edit-user-password" component={EditPassword} />

          <Route path="/analytics" component={Analytics} />
          <Route path="/test" component={Test} />

          <Route path="/mountain-ranges" component={MountainRanges} />
          <Route path="/create-user" component={CreateUser} />
          <Route path="/edit-user/:id" component={EditUser} />


          <Footer />

        </Layout>
      </Layout>

    </Provider>
  </Router>,
  document.getElementById('root')
);
