import React, { useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";

import { Menu, Layout } from 'antd';
import { UserOutlined, HomeOutlined, OrderedListOutlined, LoginOutlined, LineChartOutlined, CopyOutlined, BarChartOutlined, EyeOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import { fetchUserByID } from '../redux'
import Loader from './Loader';

const { Sider } = Layout;
const { SubMenu } = Menu;

function Sidebar({ fetchUserByID, user }) {
    useEffect(() => {
        //if session key exisit and store user object is empty dispatch action and get userdata from api uusing user id
        const session_key = localStorage.getItem('sessionKey');
        if (session_key && !user.details.type) {
            fetchUserByID(session_key)
        }

    }, [fetchUserByID, user.details.type]);

    const pathname = useLocation().pathname;
    const pathnameSplitArray = pathname.split("/")

    return (
        <>
            {
                (pathnameSplitArray[1] !== 'report'
                    && pathnameSplitArray[1] !== 'main-range'
                    && pathnameSplitArray[1] !== 'dividing-range'
                    && pathnameSplitArray[1] !== 'front-range'
                    && pathnameSplitArray[1] !== 'embedded-report-summary'
                    && pathnameSplitArray[1] !== 'view-spring-condition-report'
                    && pathnameSplitArray[1] !== 'embedded-report-summary-front-and-dividing-range'
                    && pathnameSplitArray[1] !== 'embedded-report-summary-main-range'
                    && pathnameSplitArray[1] !== 'embedded-report-summary-large'
                    && pathnameSplitArray[1] !== 'ddddddd')
                    
                    ?

                    <Sider breakpoint="lg" collapsedWidth="0"
                        onBreakpoint={broken => {
                            console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                    >
                        <div className="logo" >
                            <img alt="logo" src="../images/header-logo-hq.png" />
                        </div>

                        {
                            !user.details.type ?
                                <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
                                    {/* <Menu.Item key="1" icon={<HomeOutlined />}>
                                        <NavLink to="/" > Home </NavLink>
                                    </Menu.Item> */}

                                    <Menu.Item key="2" icon={<LoginOutlined />}> <NavLink to="/login" > Login </NavLink> </Menu.Item>
                                    {/* <Menu.Item key="3" icon={<UserOutlined />}> <NavLink to="/register" > Register </NavLink> </Menu.Item> */}
                                </Menu>

                                :

                                user.details.type === "admin" ?

                                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                                        <Menu.Item key="4" icon={<HomeOutlined />}>
                                            <NavLink to="/" > Home </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="9" icon={<LineChartOutlined />}>
                                            <NavLink to="/reports" > Reports </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="14" icon={<CopyOutlined />}>
                                            <NavLink to="/view-embedded-report" > Embedded Report </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="10" icon={<OrderedListOutlined />}>
                                            <NavLink to="/users" > Manage Users </NavLink>
                                        </Menu.Item>

                                        <SubMenu key="sub2" icon={<UserOutlined />} title="Admin">
                                            <Menu.Item key="5"><NavLink to="/edit-user-basic-details" > Edit Basic Details</NavLink></Menu.Item>
                                            <Menu.Item key="6"><NavLink to="/edit-user-password" > Edit Password</NavLink></Menu.Item>
                                            <Menu.Item key="7"><NavLink to="/logout" > Logout </NavLink> </Menu.Item>
                                        </SubMenu>
                                        <Menu.Item key="22" icon={<BarChartOutlined />}>
                                            <NavLink to="/analytics" > Analytics </NavLink>
                                        </Menu.Item>

                                        <Menu.Item key="23" icon={<OrderedListOutlined />}>
                                            <NavLink to="/mountain-ranges" > Mountain Ranges </NavLink>
                                        </Menu.Item>

                                        <SubMenu key="sub3" icon={<EyeOutlined />} title="View Archives">
                                            <Menu.Item key="11"><NavLink to="/main-range" target="_blank" rel="noopener noreferrer"> Main Range</NavLink></Menu.Item>
                                            <Menu.Item key="12"><NavLink to="/dividing-range" target="_blank" rel="noopener noreferrer"> Dividing Range</NavLink></Menu.Item>
                                            <Menu.Item key="13"><NavLink to="/front-range" target="_blank" rel="noopener noreferrer"> Front Range</NavLink> </Menu.Item>
                                        </SubMenu>
                                    </Menu>

                                    :


                                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                                        <Menu.Item key="4" icon={<HomeOutlined />}>
                                            <NavLink to="/" > Home </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="9" icon={<LineChartOutlined />}>
                                            <NavLink to="/reports" > Reports </NavLink>
                                        </Menu.Item>

                                        <SubMenu key="sub2" icon={<UserOutlined />} title="User">
                                            <Menu.Item key="5"><NavLink to="/edit-user-basic-details" > Edit Basic Details</NavLink></Menu.Item>
                                            <Menu.Item key="6"><NavLink to="/edit-user-password" > Edit Password</NavLink></Menu.Item>
                                            <Menu.Item key="7"><NavLink to="/logout" > Logout </NavLink> </Menu.Item>
                                        </SubMenu>

                                        <SubMenu key="sub3" icon={<EyeOutlined />} title="View Archives">
                                            <Menu.Item key="11"><NavLink to="/main-range" target="_blank" rel="noopener noreferrer"> Main Range</NavLink></Menu.Item>
                                            <Menu.Item key="12"><NavLink to="/dividing-range" target="_blank" rel="noopener noreferrer"> Dividing Range</NavLink></Menu.Item>
                                            <Menu.Item key="13"><NavLink to="/front-range" target="_blank" rel="noopener noreferrer"> Front Range</NavLink> </Menu.Item>
                                        </SubMenu>
                                    </Menu>

                        }


                        {(user.loading) ? <Loader title="Loading..." /> : null}

                    </Sider>
                    :
                    null
            }
        </>
    )
}


const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserByID: (key) => dispatch(fetchUserByID(key)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
