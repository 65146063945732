import React, { useState, useEffect } from 'react';
import { Layout, Modal } from 'antd';
import ContentHeader from './components/ContentHeader';
import Summery from './components/create-report/Summery';
import DangerRatings from './components/create-report/DangerRatings';

import { connect } from 'react-redux'
import Hazards from './components/create-report/Hazards';
import MoreDeatils from './components/create-report/MoreDeatils';
import axios from 'axios'
import { setNewReportLoading, setLogout, setNewReportEmpty, fetchGlobal } from './redux'
import { apiBaseUrl } from './Url'
import Loader from './components/Loader';


const { Content } = Layout;

function CreateReport({ newReport, setNewReportLoading, setLogout, history, user, setNewReportEmpty, fetchGlobal }) {
    const [step, setStep] = useState(1)

    useEffect(() => {

        if (!user.details.type) {
            history.push('/login')
        }

        setNewReportEmpty();

    }, [user.details.type, history, setNewReportEmpty]);

    function prev(id) {
        setStep(id)
    }

    function save() {
        setNewReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/save",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { report: newReport }
            })
            .then(response => {
                // console.log(response)

                if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                } else if (response.data.status === 1) {
                    success()
                } else {
                    showError(response.data.message)
                }
                setNewReportLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                showError(errorMsg)
                setNewReportLoading(false)
            })
    }

    function success() {
        Modal.success({
            content: 'Report has been successfully created.',
            onOk() { afterSuccess() },
        });
    }

    function afterSuccess() {
        setNewReportEmpty()
        fetchGlobal()
        history.push('/reports')
    }

    function showError(msg) {
        Modal.error({
            content: msg,
        });
    }

    return (
        <div>
            <ContentHeader title="Create Report" />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    {
                        (step === 1)
                            ?
                            <Summery setStep={setStep} />
                            :
                            (step === 2)
                                ?
                                <DangerRatings setStep={setStep} prev={prev} img_type="create"/>
                                :
                                (step === 3)
                                    ?
                                    <Hazards next={setStep} prev={prev} img_type="create"/>
                                    :
                                    <MoreDeatils next={save} prev={prev} img_type="create"/>
                    }


                    <br />
                </div>
                {(newReport.loading ? <Loader title="Loading..." /> : null)}
            </Content>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        newReport: state.newReport,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setNewReportLoading: (bool) => dispatch(setNewReportLoading(bool)),
        setLogout: (key) => dispatch(setLogout(key)),
        setNewReportEmpty: () => dispatch(setNewReportEmpty()),
        fetchGlobal: () => dispatch(fetchGlobal()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);
