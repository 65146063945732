import React from 'react'
import { Layout, Button, Tooltip } from 'antd';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import Loader from './Loader'

import { connect } from 'react-redux'

const { Header } = Layout;

function ContentHeader({ user, title }) {

    

    return (
        <>
            <Header className="site-layout-sub-header-background" style={{ padding: 0, marginLeft: 15, marginRight: 15 }} >
                <h2>{title}</h2>

                <div className="site-layout-sub-header-btn">
                    {(!user.details.email)
                        ?
                        <Tooltip title="Login">
                            <Link to="/login">
                                <Button shape="circle" icon={<LoginOutlined />} />
                            </Link>
                        </Tooltip>

                        :
                        <Tooltip title="Logout">
                            <Link to="/logout">
                                <Button shape="circle" icon={<LogoutOutlined />} />
                            </Link>
                        </Tooltip>
                    }
                </div>
            </Header>
            { (user.loading) ? <Loader /> : null}
        </>
    )
}



const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, null)(ContentHeader);
