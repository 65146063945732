import React from 'react'
import { Row, Col, Skeleton } from 'antd';
import moment from 'moment'

function Titledetails({ region, created_by, regional_outlook, date, region_img, img_path, forecast_confidence, published_by }) {


    return (
        <div>
            {(region) ?
                <>
                    <Row className="region-img-container" >

                        <Col span="24" >
                            <img alt={region} src={img_path + region_img} className="main-img" />
                            {/* <a href="https://reports.mountainsafetycollective.org/report/2021-06-11"> */}
                            <img alt={region} src={img_path + "sponsor_" + region_img} className="sponser-img" />
                            {/* </a> */}
                        </Col>
                    </Row>


                    <Row className="title-details-container">
                        <Col lg={11} md={24} sm={24}>
                            <h4 style={{ marginTop: "10px" }} className={(region === "Main range") ? "orange" : "green"}>Regional Outlook</h4>
                        </Col>
                        <Col lg={13} md={24} sm={24}>
                            <span>Issued:&nbsp; {(date) ? (moment)(date).format("DD/MM/YYYY") : ""}</span>
                            <br />
                            <span>Prepared By:&nbsp; {(created_by !== published_by) ? (published_by) ? created_by + " and " + published_by : created_by : created_by}</span>
                            <br />
                            <span>Confidence:&nbsp; {forecast_confidence}</span>

                        </Col>
                    </Row>
                    <Row className="title-details-container">
                        <Col span="24">
                            <p>{regional_outlook}</p>
                        </Col>
                    </Row>
                </>

                :
                <Row className="title-details-container">
                    <Col lg={11} md={24} sm={24} xs={24}>
                        <Skeleton active={true} />
                    </Col>
                </Row>
            }
        </div>
    )
}

export default Titledetails
