import React, { useEffect } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { fetchGlobal, setReportLoading } from './redux'
import Loader from './components/Loader';
import { Link } from "react-router-dom";
import Reportlist from './components/reports/ReportList';


const { Content } = Layout;

function Reports({ global, fetchGlobal, setReportLoading, history, user }) {
    useEffect(() => {

        if (!user.details.type) {
            history.push('/login')
        }

    }, [user.details.type, history]);

    useEffect(() => {
        if (global.montainRanges.length === 0) {
            fetchGlobal()
        }
    }, [fetchGlobal, global.montainRanges.length])



    return (
        <div>
            <ContentHeader title="Reports" />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">


                    <Row>
                        <Col span={24}>
                            <Link to="/create-report"><Button type="primary"   >Create a report</Button></Link>
                        </Col>
                    </Row>

                    <br />

                    <Row className="list-tbl-wrapper">
                        <Col span={24} >
                            <Reportlist data={global.reports} user={user} setReportLoading={setReportLoading} fetchGlobal={fetchGlobal} history={history} />
                        </Col>
                    </Row>
                </div>
                {(global.loading ? <Loader title="Loading..." /> : null)}
            </Content>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        global: state.global,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGlobal: () => dispatch(fetchGlobal()),
        setReportLoading: (bool) => dispatch(setReportLoading(bool)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reports);
