import React from 'react'
import { Row, Col, Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function HazardsDetails({ categorisation, img_path, setType }) {

    // const setRpeortType = () => {
    //     setType("Details");
    // }

    const widspreadice_info = "Widespread ice occurs when a cycle of climate events conspire through varied or consistent means to render the entire area hard, unyielding, ice.";

    return (
        <div className="hazard-details-container">

            {
                (categorisation.length > 0)
                    ?
                    categorisation.map(function (item, i) {
                        if (item.type === "Primary") {
                            return (
                                <Row key={i}>
                                    <Col span="24" className="header-section">
                                        {/* <Button onClick={setRpeortType}>Find out more <RightOutlined /></Button> */}
                                        <Tooltip placement="left" color="#000" title={item.characteristic ? item.characteristic_info : widspreadice_info}>
                                            <Button >About <PlusOutlined /></Button>
                                        </Tooltip>

                                        <h4 className="hazard-cat-title">
                                            <label>1</label>
                                            &nbsp;
                                            Primary Hazard:
                                            <br />{item.characteristic ? item.characteristic : "Widespread Ice"}
                                        </h4>
                                    </Col>
                                    <Col span="24" className="other-section">
                                        {/* <img src={img_path + item.hazard_img} alt={item.hazard} className="hazard-img" /> */}
                                        <p>{item.summary}</p>
                                    </Col>
                                    <Col span="24" className="other-section">
                                        <Row>
                                            {
                                                (item.elevation_id !== 0)
                                                    ?
                                                    // <h5>Hazard Elevation - <span>{item.elevation}</span></h5>
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Elevation</h5>
                                                        <img src={img_path + item.elevation_img} alt={item.elevation} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.aspect_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Aspects</h5>
                                                        <img src={img_path + item.aspect_img} alt={item.aspect} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.avalanche_sizes_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Avalanche Size</h5>
                                                        <img src={img_path + item.avalanche_size_img} alt={item.avalanche_sizes} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.likelihood_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Likelihood</h5>
                                                        <img src={img_path + item.likelihood_img} alt={item.likelihood} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }

                                            {
                                                (item.characteristic === "Spring conditions")
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <img src={img_path + 'spring_conditions.png'} alt="spring conditions" className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }

                        if (item.type === "Secondary") {
                            return (
                                <Row key={i} style={{ marginTop: 20 }}>
                                    <Col span="24" className="header-section">
                                        {/* <Button onClick={setRpeortType}>Find out more <RightOutlined /></Button> */}

                                        <Tooltip placement="left" color="#000" title={item.characteristic ? item.characteristic_info : widspreadice_info}>
                                            <Button>About <PlusOutlined /></Button>
                                        </Tooltip>

                                        <h4 className="hazard-cat-title">
                                            <label>2</label>
                                            &nbsp;
                                            Secondary Hazard:
                                            <br />{item.characteristic ? item.characteristic : "Widespread Ice"}
                                        </h4>
                                    </Col>
                                    <Col span="24" className="other-section">
                                        {/* <img src={img_path + item.hazard_img} alt={item.hazard} className="hazard-img" /> */}
                                        <p>{item.summary}</p>
                                    </Col>
                                    <Col span="24" className="other-section" >
                                        <Row>
                                            {
                                                (item.elevation_id !== 0)
                                                    ?
                                                    // <h5>Hazard Elevation - <span>{item.elevation}</span></h5>
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Elevation</h5>
                                                        <img src={img_path + item.elevation_img} alt={item.elevation} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.aspect_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Aspects</h5>
                                                        <img src={img_path + item.aspect_img} alt={item.aspect} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.avalanche_sizes_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Avalanche Size</h5>
                                                        <img src={img_path + item.avalanche_size_img} alt={item.avalanche_sizes} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.likelihood_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Likelihood</h5>
                                                        <img src={img_path + item.likelihood_img} alt={item.likelihood} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }


                                            {
                                                (item.characteristic === "Spring conditions")
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <img src={img_path + 'spring_conditions.png'} alt="spring conditions" className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }

                        if (item.type === "Tertiary") {
                            return (
                                <Row key={i} style={{ marginTop: 20 }}>
                                    <Col span="24" className="header-section">
                                        {/* <Button onClick={setRpeortType}>Find out more <RightOutlined /></Button> */}

                                        <Tooltip placement="left" color="#000" title={item.characteristic ? item.characteristic_info : widspreadice_info}>
                                            <Button>About <PlusOutlined /></Button>
                                        </Tooltip>

                                        <h4 className="hazard-cat-title">
                                            <label>3</label>
                                            &nbsp;
                                            Tertiary Hazard:
                                            <br />{item.characteristic ? (item.characteristic === "Spring conditions") ? "Spring conditions" : item.characteristic : "Widespread Ice"}
                                        </h4>
                                    </Col>
                                    <Col span="24" className="other-section">
                                        {/* <img src={img_path + item.hazard_img} alt={item.hazard} className="hazard-img" /> */}
                                        <p>{item.summary}</p>
                                    </Col>
                                    <Col span="24" className="other-section" >
                                        <Row>
                                            {
                                                (item.elevation_id !== 0)
                                                    ?
                                                    // <h5>Hazard Elevation - <span>{item.elevation}</span></h5>
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Elevation</h5>
                                                        <img src={img_path + item.elevation_img} alt={item.elevation} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.aspect_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Aspects</h5>
                                                        <img src={img_path + item.aspect_img} alt={item.aspect} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.avalanche_sizes_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Avalanche Size</h5>
                                                        <img src={img_path + item.avalanche_size_img} alt={item.avalanche_sizes} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.likelihood_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Likelihood</h5>
                                                        <img src={img_path + item.likelihood_img} alt={item.likelihood} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }


                                            {
                                                (item.characteristic === "Spring conditions")
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15, marginTop: 20 }}>
                                                        <img src={img_path + 'spring_conditions.png'} alt="spring conditions" className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }


                        if (item.type === "Quaternary") {
                            return (
                                <Row key={i} style={{ marginTop: 20 }}>
                                    <Col span="24" className="header-section">
                                        {/* <Button onClick={setRpeortType}>Find out more <RightOutlined /></Button> */}

                                        <Tooltip placement="left" color="#000" title={item.characteristic ? item.characteristic_info : widspreadice_info}>
                                            <Button>About <PlusOutlined /></Button>
                                        </Tooltip>

                                        <h4 className="hazard-cat-title">
                                            <label>4</label>
                                            &nbsp;
                                            Quaternary Hazard:
                                            <br />{item.characteristic ? (item.characteristic === "Spring conditions") ? "Spring conditions" : item.characteristic + "avalanche" : "Widespread Ice"}
                                        </h4>
                                    </Col>
                                    <Col span="24" className="other-section">
                                        {/* <img src={img_path + item.hazard_img} alt={item.hazard} className="hazard-img" /> */}
                                        <p>{item.summary}</p>
                                    </Col>
                                    <Col span="24" className="other-section" >
                                        <Row>
                                            {
                                                (item.elevation_id !== 0)
                                                    ?
                                                    // <h5>Hazard Elevation - <span>{item.elevation}</span></h5>
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Elevation</h5>
                                                        <img src={img_path + item.elevation_img} alt={item.elevation} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.aspect_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Hazard Aspects</h5>
                                                        <img src={img_path + item.aspect_img} alt={item.aspect} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.avalanche_sizes_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Avalanche Size</h5>
                                                        <img src={img_path + item.avalanche_size_img} alt={item.avalanche_sizes} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                (item.likelihood_id !== 0)
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15 }}>
                                                        <h5>Likelihood</h5>
                                                        <img src={img_path + item.likelihood_img} alt={item.likelihood} className="cat-img" />
                                                    </Col>
                                                    : null
                                            }


                                            {
                                                (item.characteristic === "Spring conditions")
                                                    ?
                                                    <Col md={12} sm={24} style={{ marginBottom: 15, marginTop: 20 }}>
                                                        <img src={img_path + 'spring_conditions.png'} alt="spring conditions" className="cat-img" />
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }
                        return null
                    })

                    :
                    <Col span="24" className="other-section" style={{padding: "50px 50px"}}>
                        <center>
                            <label>There are no specific hazard distribution characteristics associated with todays conditions.</label>
                        </center>
                    </Col>

            }
        </div>
    )
}

export default HazardsDetails
