import React, { useState, useEffect } from 'react'
import { Row, Col, Button, DatePicker, Tooltip, Skeleton } from 'antd';
import { PlusOutlined, RightOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment'

function DangerRatings({ alpine_hazards, sub_alpine_hazards, travel_and_terrain_advice, region, img_path, setType, date, override_alpine_rating, override_sub_alpine_rating }) {
    const [total_alpine_rating, set_total_alpine_rating] = useState(0)
    const [alpine_msg, set_alpine_msg] = useState("")
    const [total_sub_alpine_rating, set_total_sub_alpine_rating] = useState(0)
    const [sub_alpine_msg, set_sub_alpine_msg] = useState("")
    const [range_img, set_range_img] = useState("")
    const [showRegionalOutlookCalander, set_showRegionalOutlookCalander] = useState(false)


    useEffect(() => {

          //according to new requirement on 2022-03-25 
        // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
        const alpineExtra = alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');
        const subAlpineExtra = sub_alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');


        var alpine_tot = 0;
        for (var i = 0; i < alpine_hazards.length; i++) {
            alpine_tot = alpine_tot + alpine_hazards[i].rating
        }

         // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
         if (alpineExtra) {
            if (alpine_tot < 4) {
                alpine_tot = 4
            }
        }

        if(override_alpine_rating !== 0){
            set_total_alpine_rating(override_alpine_rating)
        }else{

            set_total_alpine_rating(alpine_tot)

        }

        var sub_alpine_tot = 0;
        for (i = 0; i < sub_alpine_hazards.length; i++) {
            sub_alpine_tot = sub_alpine_tot + sub_alpine_hazards[i].rating
        }

          // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
          if (subAlpineExtra) {
            if (sub_alpine_tot < 4) {
                sub_alpine_tot = 4
            }
        }

        if(override_sub_alpine_rating !== 0){
            set_total_sub_alpine_rating(override_sub_alpine_rating)
        }else{

            set_total_sub_alpine_rating(sub_alpine_tot)

        }


    }, [alpine_hazards, total_sub_alpine_rating, sub_alpine_hazards, override_alpine_rating, override_sub_alpine_rating])

    useEffect(() => {

        console.log("total_alpine_rating",total_alpine_rating);

        if (total_alpine_rating >= 8) {
            // set_alpine_msg("TRAVEL NOT RECOMMENDED")
            set_alpine_msg("danger_rating_03.png")
        } else if (total_alpine_rating <= 7 && total_alpine_rating >= 4) {
            // set_alpine_msg("EXTRA CAUTION")
            set_alpine_msg("danger_rating_02.png")
        } else {
            // set_alpine_msg("CAUTION")
            set_alpine_msg("danger_rating_01.png")
        }

        if (total_sub_alpine_rating >= 8) {
            // set_sub_alpine_msg("TRAVEL NOT RECOMMENDED")
            set_sub_alpine_msg("danger_rating_03.png")
        } else if (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4) {
            // set_sub_alpine_msg("EXTRA CAUTION")
            set_sub_alpine_msg("danger_rating_02.png")
        } else {
            // set_sub_alpine_msg("CAUTION")
            set_sub_alpine_msg("danger_rating_01.png")
        }




    }, [total_alpine_rating, total_sub_alpine_rating, alpine_hazards, sub_alpine_hazards])


    useEffect(() => {


            if (total_alpine_rating <= 3 && total_sub_alpine_rating <= 3) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_01.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_01.png")
                } else {
                    set_range_img("main_range_01.png")
                }
            }

            if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && total_sub_alpine_rating <= 3) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_02.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_02.png")
                } else {
                    set_range_img("main_range_02.png")
                }
            }

            if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_03.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_03.png")
                } else {
                    set_range_img("main_range_03.png")
                }
            }

            if (total_alpine_rating >= 8 && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_04.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_04.png")
                } else {
                    set_range_img("main_range_04.png")
                }
            }

            if (total_alpine_rating >= 8 && total_sub_alpine_rating >= 8) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_05.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_05.png")
                } else {
                    set_range_img("main_range_05.png")
                }
            }

            if (total_alpine_rating >= 8 && total_sub_alpine_rating <= 3) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_06.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_06.png")
                } else {
                    set_range_img("main_range_06.png")
                }
            }

            if (total_alpine_rating <= 3 && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_07.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_07.png")
                } else {
                    set_range_img("main_range_07.png")
                }
            }

            if (total_alpine_rating <= 3 && total_sub_alpine_rating >= 8) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_08.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_08.png")
                } else {
                    set_range_img("main_range_08.png")
                }
            }


            if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && total_sub_alpine_rating >= 8) {
                if (region === "Dividing Range") {
                    set_range_img("dividing_range_09.png")
                } else if (region === "Front Range") {
                    set_range_img("front_range_09.png")
                } else {
                    set_range_img("main_range_09.png")
                }
            }

    }, [total_alpine_rating, total_sub_alpine_rating, region, alpine_hazards, sub_alpine_hazards])



    // useEffect(() => {

    //      //according to new requirement on 2022-03-25 
    //     // If there is a moderate or considerable avalanche danger, then the report should always show ‘Extra Caution Recommended' even if no other dangers are present.

    //     const alpineExtra = alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');
    //     const subAlpineExtra = sub_alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');

    //     //if alpine or subalpine  selected considerbale or moderate avalanche danger
    //     if (alpineExtra || subAlpineExtra) {

    //         if (alpineExtra && total_sub_alpine_rating <= 3) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_02.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_02.png")
    //             } else {
    //                 set_range_img("main_range_02.png")
    //             }
    //         }

    //         if (alpineExtra && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_03.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_03.png")
    //             } else {
    //                 set_range_img("main_range_03.png")
    //             }
    //         }


    //         if (alpineExtra && total_sub_alpine_rating >= 8) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_09.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_09.png")
    //             } else {
    //                 set_range_img("main_range_09.png")
    //             }
    //         }

    //         // --------------------

    //         if (subAlpineExtra && total_alpine_rating <= 3) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_07.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_07.png")
    //             } else {
    //                 set_range_img("main_range_07.png")
    //             }
    //         }

    //         if (subAlpineExtra && (total_alpine_rating <= 7 && total_alpine_rating >= 4)) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_03.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_03.png")
    //             } else {
    //                 set_range_img("main_range_03.png")
    //             }
    //         }


    //         if (subAlpineExtra && total_alpine_rating >= 8) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_04.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_04.png")
    //             } else {
    //                 set_range_img("main_range_04.png")
    //             }
    //         }

    //         if (alpineExtra && subAlpineExtra) {
    //             if (region === "Dividing Range") {
    //                 set_range_img("dividing_range_03.png")
    //             } else if (region === "Front Range") {
    //                 set_range_img("front_range_03.png")
    //             } else {
    //                 set_range_img("main_range_03.png")
    //             }
    //         }

           
    //     }

    // }, [total_alpine_rating, total_sub_alpine_rating, region, alpine_hazards, sub_alpine_hazards])

    const setRpeortType = () => {
        setType("Hazards");
    }


    const selected_alpine_hazards = alpine_hazards.map(function (item, i) {
        if (item.name === "Widespread ice danger" || item.name === "High avalanche danger" || item.name === "Moderate avalanche danger" || item.name === "Considerable avalanche danger") {
            return (
                <Row key={i} style={{ marginBottom: 10 }}>
                    {/* <label className="danger-msg-lbl">{item.name}</label> */}
                    <Col lg={15} md={24} sm={24}>
                        <img src={img_path + "report/" + item.img} alt="danger-alpine-rating" className="img-danger-rating-" />
                    </Col>
                    {/* &nbsp;
                        &nbsp; */}
                    <Col lg={9} md={24} sm={24}>
                        <Button className="low-btn" onClick={e => setRpeortType()}> Find out more <RightOutlined /></Button>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row key={i} style={{ marginBottom: 10 }}>
                    {/* <label className="danger-msg-lbl">{item.name}</label> */}
                    <Col lg={15} md={24} sm={24}>
                        <img src={img_path + "report/" + item.img} alt="danger-alpine-rating" className="img-danger-rating-" />
                    </Col>
                    {/* &nbsp;
                        &nbsp; */}

                    <Col lg={9} md={24} sm={24}>
                        <Tooltip placement="top" color="#000" title={item.info}>
                            <Button className="low-btn"> About&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<PlusOutlined /></Button>
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    })

    const selected_sub_alpine_hazards = sub_alpine_hazards.map(function (item, i) {
        if (item.name === "Widespread ice danger" || item.name === "High avalanche danger" || item.name === "Moderate avalanche danger" || item.name === "Considerable avalanche danger") {
            return (
                <Row key={i} style={{ marginBottom: 10 }}>
                    <Col lg={15} md={24} sm={24}>
                        <img src={img_path + "report/" + item.img} alt="danger-alpine-rating" className="img-danger-rating-" />
                    </Col>
                    {/* &nbsp;
                        &nbsp; */}
                    <Col lg={9} md={24} sm={24}>
                        <Button className="low-btn" onClick={e => setRpeortType()}> Find out more <RightOutlined /></Button>
                    </Col>
                </Row>
            )

        } else {
            return (
                <Row key={i} style={{ marginBottom: 10 }}>
                    <Col lg={15} md={24} sm={24}>
                        <img src={img_path + "report/" + item.img} alt="danger-alpine-rating" className="img-danger-rating-" />
                    </Col>
                    {/* &nbsp;
                        &nbsp; */}

                    <Col lg={9} md={24} sm={24}>
                        <Tooltip placement="top" color="#000" title={item.info}>
                            <Button className="low-btn"> About&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<PlusOutlined /></Button>
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    })

    const toggleRegionalOutlookClander = (e) => {
        e.preventDefault();
        if (showRegionalOutlookCalander) {
            set_showRegionalOutlookCalander(false)
        } else {
            set_showRegionalOutlookCalander(true)
        }
    }

    function onDateChange(e, region) {
        const date = moment(new Date(e)).format('YYYY-MM-DD')
        // console.log(date);

        if (region === "Main range") {
            window.location.replace('/main-range/' + date)
        }

        if (region === "Dividing Range") {
            window.location.replace('/dividing-range/' + date)
        }

        if (region === "Front Range") {
            window.location.replace('/front-range/' + date)
        }
    }

    return (
        <div>
            {(region) ?
                <div className="danger-rating-container">
                    <Row>
                        <Col lg={10} md={24} sm={24}>
                            <img src={img_path + range_img} alt="danger-alpine-rating" className="range_img" />
                        </Col>
                        <Col lg={14} md={24} sm={24}>
                            <div >
                                <span>Alpine</span>
                                <br />
                                {/* <label className="danger-msg-lbl">{alpine_msg}</label> */}
                                <img src={img_path + alpine_msg} alt="danger-alpine-rating" className="img-danger-rating" />
                            </div>
                            <div style={{ marginTop: 5 }}>
                                {/* <label className="danger-msg-lbl">{sub_alpine_msg}</label> */}
                                <img src={img_path + sub_alpine_msg} alt="danger-alpine-rating" className="img-danger-rating" />
                                <br />
                                <span>Subalpine</span>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                {/* <Button className="low-btn"> About how MSC danger rating work <PlusOutlined /></Button> */}
                                <a href="https://mountainsafetycollective.org/australian-mountain-hazards">
                                    <img src={img_path + "danger_ratings.png"} alt="danger-alpine-rating" className="img-danger-rating" />
                                </a>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <Col md={24} sm={24}>
                            <h5>Travel and Terrain advice</h5>
                            <p>{travel_and_terrain_advice}</p>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 25 }}>
                        <Col md={24} sm={24}>
                            <h5>Alpine Conditions</h5>
                            {selected_alpine_hazards}
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 25 }}>
                        <Col md={24} sm={24}>
                            <h5>Subalpine Conditions</h5>
                            {selected_sub_alpine_hazards}
                        </Col>
                    </Row>
                </div>


                :
                <div>
                    <Skeleton active={true} />
                    <Skeleton active={true} />
                    <Skeleton active={true} />
                </div>
            }


            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Row style={{ marginTop: 25 }}>
                    <div className="report-other-tab-btn" href="/" onClick={e => toggleRegionalOutlookClander(e)}>
                        <label>Regional Outlook Archive</label>
                        {
                            (showRegionalOutlookCalander)
                                ?
                                <span><MinusOutlined /></span>
                                :
                                <span><PlusOutlined /></span>
                        }
                    </div>
                </Row>
                {
                    (showRegionalOutlookCalander)
                        ?
                        <Row>
                            <div className="regional-outlook-calander">
                                <DatePicker onChange={e => onDateChange(e, region)}
                                    value={moment(date, 'YYYY/MM/DD')}
                                    // defaultValue={moment(date, 'DD/MM/YYYY')} 
                                    format={'DD/MM/YYYY'}
                                    open={true} />
                            </div>
                        </Row>
                        :
                        null
                }
            </div>


            {/* <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Row>
                    <div className="report-other-tab-btn">
                        <label>Danger Ratings</label>
                        <span><PlusOutlined /></span>
                    </div>
                </Row>
            </div> */}
        </div>
    )
}

export default DangerRatings
