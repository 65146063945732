import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Radio, Skeleton, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import { apiBaseUrl } from '../../Url'

const { confirm } = Modal;

function DangerRatings({ alpine_hazards, sub_alpine_hazards, region, img_path, fetchGlobal, setReportLoading, history, match, override_sub_alpine_rating, override_alpine_rating }) {
    const [total_alpine_rating, set_total_alpine_rating] = useState(0)
    const [alpine_msg, set_alpine_msg] = useState("")
    const [total_sub_alpine_rating, set_total_sub_alpine_rating] = useState(0)
    const [sub_alpine_msg, set_sub_alpine_msg] = useState("")
    const [range_img, set_range_img] = useState("")
    const [alpine_override, set_alpine_override] = useState("")
    const [sub_alpine_override, set_sub_alpine_override] = useState("")


    useEffect(() => {

        //according to new requirement on 2022-03-25 
        // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
        const alpineExtra = alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');
        const subAlpineExtra = sub_alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');


        var alpine_tot = 0;
        for (var i = 0; i < alpine_hazards.length; i++) {
            alpine_tot = alpine_tot + alpine_hazards[i].rating
        }

        // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
        if (alpineExtra) {
            if (alpine_tot < 4) {
                alpine_tot = 4
            }
        }


        if (alpine_override === "") {
            if (override_alpine_rating === 0) {
                set_total_alpine_rating(alpine_tot)
            } else {
                set_total_alpine_rating(override_alpine_rating)
            }
        }

        var sub_alpine_tot = 0;
        for (i = 0; i < sub_alpine_hazards.length; i++) {
            sub_alpine_tot = sub_alpine_tot + sub_alpine_hazards[i].rating
        }

        // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
        if (subAlpineExtra) {
            if (sub_alpine_tot < 4) {
                sub_alpine_tot = 4
            }
        }

        if (sub_alpine_override === "") {
            if (override_sub_alpine_rating === 0) {
                set_total_sub_alpine_rating(sub_alpine_tot)
            } else {
                set_total_sub_alpine_rating(override_sub_alpine_rating)
            }
        }

        // console.log("override_alpine_rating", override_alpine_rating);
        // console.log("override_sub_alpine_rating", override_sub_alpine_rating);

    }, [alpine_hazards, total_sub_alpine_rating, sub_alpine_hazards, sub_alpine_override, alpine_override, override_alpine_rating, override_sub_alpine_rating])

    useEffect(() => {

        if (total_alpine_rating >= 8) {
            // set_alpine_msg("TRAVEL NOT RECOMMENDED")
            set_alpine_msg("danger_rating_03.png")
        } else if (total_alpine_rating <= 7 && total_alpine_rating >= 4) {
            // set_alpine_msg("EXTRA CAUTION")
            set_alpine_msg("danger_rating_02.png")
        } else {
            // set_alpine_msg("CAUTION")
            set_alpine_msg("danger_rating_01.png")
        }

        if (total_sub_alpine_rating >= 8) {
            // set_sub_alpine_msg("TRAVEL NOT RECOMMENDED")
            set_sub_alpine_msg("danger_rating_03.png")
        } else if (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4) {
            // set_sub_alpine_msg("EXTRA CAUTION")
            set_sub_alpine_msg("danger_rating_02.png")
        } else {
            // set_sub_alpine_msg("CAUTION")
            set_sub_alpine_msg("danger_rating_01.png")
        }




    }, [total_alpine_rating, total_sub_alpine_rating, alpine_hazards, sub_alpine_hazards])


    useEffect(() => {


        if (total_alpine_rating <= 3 && total_sub_alpine_rating <= 3) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_01.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_01.png")
            } else {
                set_range_img("main_range_01.png")
            }
        }

        if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && total_sub_alpine_rating <= 3) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_02.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_02.png")
            } else {
                set_range_img("main_range_02.png")
            }
        }

        if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_03.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_03.png")
            } else {
                set_range_img("main_range_03.png")
            }
        }

        if (total_alpine_rating >= 8 && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_04.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_04.png")
            } else {
                set_range_img("main_range_04.png")
            }
        }

        if (total_alpine_rating >= 8 && total_sub_alpine_rating >= 8) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_05.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_05.png")
            } else {
                set_range_img("main_range_05.png")
            }
        }

        if (total_alpine_rating >= 8 && total_sub_alpine_rating <= 3) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_06.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_06.png")
            } else {
                set_range_img("main_range_06.png")
            }
        }

        if (total_alpine_rating <= 3 && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_07.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_07.png")
            } else {
                set_range_img("main_range_07.png")
            }
        }

        if (total_alpine_rating <= 3 && total_sub_alpine_rating >= 8) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_08.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_08.png")
            } else {
                set_range_img("main_range_08.png")
            }
        }


        if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && total_sub_alpine_rating >= 8) {
            if (region === "Dividing Range") {
                set_range_img("dividing_range_09.png")
            } else if (region === "Front Range") {
                set_range_img("front_range_09.png")
            } else {
                set_range_img("main_range_09.png")
            }
        }

    }, [total_alpine_rating, total_sub_alpine_rating, region, alpine_hazards, sub_alpine_hazards])

    const override_alpine_outcome = (e) => {
        set_alpine_override(e.target.value);

        if (e.target.value === "usual_caution") {
            set_total_alpine_rating(2)
        } else if (e.target.value === "extra_caution") {
            set_total_alpine_rating(5)
        } else {
            set_total_alpine_rating(9)
        }
    }

    const override_sub_alpine_outcome = (e) => {
        set_sub_alpine_override(e.target.value);

        if (e.target.value === "usual_caution") {
            set_total_sub_alpine_rating(2)
        } else if (e.target.value === "extra_caution") {
            set_total_sub_alpine_rating(5)
        } else {
            set_total_sub_alpine_rating(9)
        }
    }

    const confirmOverride = (e) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Are You Sure?",
            onOk() {
                overrideReport();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const overrideReport = () => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/oveerideOutcome",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: match.params.id, alpine: total_alpine_rating, subalpine: total_sub_alpine_rating }
            })
            .then(response => {
                fetchGlobal()
                setReportLoading(false)
                history.push('/view-and-publish-report/' + match.params.id)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
            })
    }

    const confirmClear = (e) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Are You Sure?",
            onOk() {
                clear();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const clear = (e) => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/oveerideOutcome",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: match.params.id, alpine: 0, subalpine: 0 }
            })
            .then(response => {
                fetchGlobal()
                setReportLoading(false)
                history.push('/view-and-publish-report/' + match.params.id)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
            })
    }

    return (

        <div>
            {(region) ?
                <>
                    <div className="danger-rating-container">
                        <Row>
                            <Col lg={10} md={24} sm={24}>
                                <img src={img_path + range_img} alt="danger-alpine-rating" className="range_img" />
                            </Col>
                            <Col lg={14} md={24} sm={24}>
                                <div >
                                    <span>Alpine</span>
                                    <br />
                                    {/* <label className="danger-msg-lbl">{alpine_msg}</label> */}
                                    <img src={img_path + alpine_msg} alt="danger-alpine-rating" className="img-danger-rating" />
                                </div>
                                <div style={{ marginTop: 5 }}>
                                    {/* <label className="danger-msg-lbl">{sub_alpine_msg}</label> */}
                                    <img src={img_path + sub_alpine_msg} alt="danger-alpine-rating" className="img-danger-rating" />
                                    <br />
                                    <span>Subalpine</span>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={24} md={24} sm={24}>
                                <div style={{ marginTop: 5 }}>
                                    <hr />
                                </div>

                                <div style={{ marginTop: 5 }}>
                                    <h5>Change forcast outcome</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg={12} md={24} sm={24}>
                                <span>Alpine</span>
                                <Radio.Group value={alpine_override} onChange={override_alpine_outcome}>
                                    <Radio value={"usual_caution"}><img src={img_path + 'danger_rating_01.png'} alt="danger-alpine-rating" className="img-danger-rating" style={{ marginBottom: 3 }} /></Radio>
                                    <Radio value={"extra_caution"}><img src={img_path + 'danger_rating_02.png'} alt="danger-alpine-rating" className="img-danger-rating" style={{ marginBottom: 3 }} /></Radio>
                                    <Radio value={"travel_not_recommended"}><img src={img_path + 'danger_rating_03.png'} alt="danger-alpine-rating" className="img-danger-rating" style={{ marginBottom: 3 }} /></Radio>
                                </Radio.Group>
                            </Col>
                            <Col lg={12} md={24} sm={24}>
                                <span>Sub Alpine</span>
                                <Radio.Group value={sub_alpine_override} onChange={override_sub_alpine_outcome}>
                                    <Radio value={"usual_caution"}><img src={img_path + 'danger_rating_01.png'} alt="danger-alpine-rating" className="img-danger-rating" style={{ marginBottom: 3 }} /></Radio>
                                    <Radio value={"extra_caution"}><img src={img_path + 'danger_rating_02.png'} alt="danger-alpine-rating" className="img-danger-rating" style={{ marginBottom: 3 }} /></Radio>
                                    <Radio value={"travel_not_recommended"}><img src={img_path + 'danger_rating_03.png'} alt="danger-alpine-rating" className="img-danger-rating" style={{ marginBottom: 3 }} /></Radio>
                                </Radio.Group>
                            </Col>

                        </Row>


                    </div>


                    <Row style={{ marginTop: 20 }}>
                        &nbsp;
                        <Button type="secondary" onClick={e => confirmClear(e)}>Revert Back</Button>
                        &nbsp; &nbsp;
                        <Button type="primary" onClick={e => confirmOverride(e)}>Save</Button>
                    </Row>
                </>


                :
                <div>
                    <Skeleton active={true} />
                </div>
            }
        </div>
    )
}

export default DangerRatings
