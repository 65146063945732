import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Radio, message } from 'antd';
import { connect } from 'react-redux'
import { setPrimaryHazard, setSecondaryHazard, setTertiaryHazard, setQuaternaryHazard } from '../../redux'
import Primary from './hazards/Primary';
import Secondary from './hazards/Secondary';
import Tertiary from './hazards/Tertiary';
import Quaternary from './hazards/Quaternary';

const possibleHazards = ['Widespread ice danger', 'Moderate avalanche danger', "Considerable avalanche danger", "High avalanche danger", "Low Avalanche Danger Detailed"]


function Hazards({ global, setPrimaryHazard, newReport, setSecondaryHazard, setTertiaryHazard, setQuaternaryHazard, next, prev, img_type }) {
    const [maxHazards, setMaxHazards] = useState([])
    const [step, setStep] = useState(1)

    const img_path = (img_type === "edit") ? "../images/" : "./images/";

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    useEffect(() => {

        // adding key "type" and value "Alpine or Subalpine" to recorganize correct elevation
        const alpineHazards_ = JSON.parse(JSON.stringify(newReport.alpineHazards))
        for (var c = 0; c < alpineHazards_.length; c++) {
            alpineHazards_[c].type = "Alpine"
        }

        const subAlpineHazards_ = JSON.parse(JSON.stringify(newReport.subAlpineHazards))
        for (c = 0; c < subAlpineHazards_.length; c++) {
            subAlpineHazards_[c].type = "Subalpine"
        }


        //combined two arrays alpine and subalpine
        let newArray = alpineHazards_.concat(subAlpineHazards_)
        console.log(newArray);

        // sort comnined array descending
        newArray = newArray.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));

        // remove duplicated objects
        // var obj = {};

        // for (var i = 0, len = newArray.length; i < len; i++) {
        //     obj[newArray[i]['name']] = newArray[i];
        // }

        // newArray = [];
        // for (var key in obj) {
        //     newArray.push(obj[key]);
        // }

        // filter by possible hazards
        let finalArray = []
        for (var k = 0; k < newArray.length; k++) {
            for (var i = 0; i < possibleHazards.length; i++) {
                if (newArray[k].name === possibleHazards[i]) {
                    finalArray.push(newArray[k])
                }
            }
        }

        //get 4 highest rating objects
        finalArray = finalArray.slice(0, 4)

        setMaxHazards(finalArray)

        // if no any relevent hazard selected skip this and go directly to more details section
        if (finalArray.length <= 0) {
            next(4)
        }


    }, [newReport.alpineHazards, newReport.subAlpineHazards, next])

    const maxHazardsRadios = maxHazards.map(function (item, i) {
        var elevation = 0;
        if (item.type === "Alpine") {
            elevation = 1 // 1 is alpine, need to be hard coded becasue of requirement change
        } else {
            elevation = 2 // 2 is subalpine
        }
        return (
            <div key={i}>
                <Radio value={item.id + "_" + elevation}>{item.name + " - " + item.type}</Radio>
                <br />
            </div>
        )
    })

    const hazardCharacteristicsRadios = global.hazardCharacteristics.map(function (item, i) {
        return (
            <div key={i}>
                <Radio value={item.id}>{item.name}</Radio>
                <br />
            </div>
        )
    })

    const hazardElevationRadios = global.hazardElevation.map(function (item, i) {
        return (
            <div key={i} style={{ float: "left", marginRight: 40 }}>
                <center>
                    <label>
                        <img src={img_path + item.img} alt={item.name} className="report-for-elevation-img" />
                        <br />
                        <Radio value={item.id}></Radio>
                    </label>
                </center>
            </div>
        )
    })

    const hazardAspectsRadios = global.hazardAspects.map(function (item, i) {
        return (
            <div key={i} style={{ float: "left", marginRight: 40 }}>
                <center>
                    <label>
                        <img src={img_path + item.img} alt={item.name} className="report-for-elevation-img" />
                        <br />
                        <Radio value={item.id}></Radio>
                    </label>
                </center>
            </div>
        )
    })

    const avalancheSizesRadios = global.avalancheSizes.map(function (item, i) {
        return (
            <div key={i} style={{ float: "left", marginRight: 40, }}>
                <center>
                    <label>
                        <img src={img_path + item.img} alt={item.name} className="report-for-likihood-img" />
                        <br />
                        <Radio value={item.id}></Radio>
                    </label>
                </center>
            </div>
        )
    })

    const hazardLikelihoodRadios = global.hazardLikelihood.map(function (item, i) {
        return (
            <div key={i} style={{ float: "left", marginRight: 40, }}>
                <center>
                    <label>
                        <img src={img_path + item.img} alt={item.name} className="report-for-likihood-img" />
                        <br />
                        <Radio value={item.id}></Radio>
                    </label>
                </center>
            </div>
        )
    })

    // console.log(primary);

    function primary(e) {
        e.preventDefault();

        if (primaryValidation()) {
            if (maxHazards.length === 1) {
                next(4)
            } else {
                setStep(2)
            }
        }
    }

    function primaryValidation() {
        let formIsValid = true;

        if (newReport.primaryHazard.hazardId === 0) {
            formIsValid = false;
            message.error('Select primary hazard.');
            return formIsValid;
        }

        return formIsValid;
    }


    function secondary(e) {
        e.preventDefault();

        if (secondaryValidation()) {
            if (maxHazards.length === 2) {
                next(4)
            } else {
                setStep(3)
            }
        }
    }

    function secondaryValidation() {
        let formIsValid = true;

        if (newReport.secondaryHazard.hazardId === 0) {
            formIsValid = false;
            message.error('Select secondary hazard.');
            return formIsValid;
        }

        return formIsValid;
    }

    function tertiary(e) {
        e.preventDefault();

        if (tertiaryValidation()) {
            next(4)
        }
    }

    // if u want a another option (4 options) uncomment this
    // function tertiary(e) {
    //     e.preventDefault();

    //     if (tertiaryValidation()) {
    //         if (maxHazards.length === 3) {
    //             next(4)
    //         } else {
    //             setStep(4)
    //         }
    //     }
    // }

    function tertiaryValidation() {
        let formIsValid = true;

        if (newReport.tertiaryHazard.hazardId === 0) {
            formIsValid = false;
            message.error('Select tertiary hazard.');
            return formIsValid;
        }

        return formIsValid;
    }

    function quaternary(e) {
        e.preventDefault();

        if (quaternaryValidation()) {
            next(4)
        }
    }

    function quaternaryValidation() {
        let formIsValid = true;

        if (newReport.quaternaryHazard.hazardId === 0) {
            formIsValid = false;
            message.error('Select quaternaryHazard hazard.');
            return formIsValid;
        }

        return formIsValid;
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <h2>Hazards</h2>
                </Col>
            </Row>
            <br />

            {(step === 1)
                ?
                <div>
                    <Primary
                        setPrimaryHazard={setPrimaryHazard}
                        newReport={newReport}
                        maxHazardsRadios={maxHazardsRadios}
                        hazardCharacteristicsRadios={hazardCharacteristicsRadios}
                        hazardElevationRadios={hazardElevationRadios}
                        hazardAspectsRadios={hazardAspectsRadios}
                        avalancheSizesRadios={avalancheSizesRadios}
                        hazardLikelihoodRadios={hazardLikelihoodRadios}
                    />



                    <br />
                    <Row>
                        <Col md={24} sm={24}>
                            <Button className="report-form-back-btn" onClick={e => prev(2)}>Back</Button>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button className="report-form-next-btn" type="primary" onClick={e => primary(e)}>Next</Button>
                        </Col>
                    </Row>

                </div>

                : null
            }

            {(step === 2)
                ?
                <div>
                    <Secondary
                        setSecondaryHazard={setSecondaryHazard}
                        newReport={newReport}
                        maxHazardsRadios={maxHazardsRadios}
                        hazardCharacteristicsRadios={hazardCharacteristicsRadios}
                        hazardElevationRadios={hazardElevationRadios}
                        hazardAspectsRadios={hazardAspectsRadios}
                        avalancheSizesRadios={avalancheSizesRadios}
                        hazardLikelihoodRadios={hazardLikelihoodRadios}
                    />



                    <br />
                    <Row>
                        <Col md={24} sm={24}>
                            <Button className="report-form-back-btn" onClick={e => setStep(1)}>Back</Button>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button className="report-form-next-btn" type="primary" onClick={e => secondary(e)}>Next</Button>

                        </Col>
                    </Row>

                </div>

                : null
            }

            {(step === 3)
                ?
                <div>
                    <Tertiary
                        setTertiaryHazard={setTertiaryHazard}
                        newReport={newReport}
                        maxHazardsRadios={maxHazardsRadios}
                        hazardCharacteristicsRadios={hazardCharacteristicsRadios}
                        hazardElevationRadios={hazardElevationRadios}
                        hazardAspectsRadios={hazardAspectsRadios}
                        avalancheSizesRadios={avalancheSizesRadios}
                        hazardLikelihoodRadios={hazardLikelihoodRadios}
                    />



                    <br />
                    <Row>
                        <Col md={24} sm={24}>
                            <Button className="report-form-back-btn" onClick={e => setStep(2)}>Back</Button>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button className="report-form-next-btn" type="primary" onClick={e => tertiary(e)}>Next</Button>
                        </Col>
                    </Row>

                </div>

                : null
            }


            {(step === 4)
                ?
                <div>
                    <Quaternary
                        setQuaternaryHazard={setQuaternaryHazard}
                        newReport={newReport}
                        maxHazardsRadios={maxHazardsRadios}
                        hazardCharacteristicsRadios={hazardCharacteristicsRadios}
                        hazardElevationRadios={hazardElevationRadios}
                        hazardAspectsRadios={hazardAspectsRadios}
                        avalancheSizesRadios={avalancheSizesRadios}
                        hazardLikelihoodRadios={hazardLikelihoodRadios}
                    />



                    <br />
                    <Row>
                        <Col md={24} sm={24}>
                            <Button className="report-form-back-btn" onClick={e => setStep(3)}>Back</Button>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button className="report-form-next-btn" type="primary" onClick={e => quaternary(e)}>Next</Button>
                        </Col>
                    </Row>

                </div>

                : null
            }

        </div>
    )
}


const mapStateToProps = state => {
    return {
        newReport: state.newReport,
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPrimaryHazard: (obj) => dispatch(setPrimaryHazard(obj)),
        setSecondaryHazard: (obj) => dispatch(setSecondaryHazard(obj)),
        setTertiaryHazard: (obj) => dispatch(setTertiaryHazard(obj)),
        setQuaternaryHazard: (obj) => dispatch(setQuaternaryHazard(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hazards);
