import React, { useState, useEffect } from 'react'
import { Layout } from 'antd';
import { Input, Button, message } from 'antd';
import { Row, Col } from 'antd';

import { connect } from 'react-redux'
import { fetchUser, setUserLoading,fetchUserFaliur } from './redux'
import ContentHeader from './components/ContentHeader';
import Loader from './components/Loader';

const { Content } = Layout;
const sha512 = require("js-sha512");

function Login({ fetchUser, setUserLoading, user, history, fetchUserFaliur }) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        // fetchUserFaliur()
        if (user.details.email) {
            history.push("/reports")
        }

    }, [user, history]);


    function handleLogin(e) {
        e.preventDefault();

        if (handleValidation()) {
            login();
        }
    }

    function handleValidation() {
        let formIsValid = true;

        //email
        if (!email) {
            formIsValid = false;
            // setValidator("Please insert your email address.");
            message.error('Please insert your email address.');
            return formIsValid;
        }

        //password
        if (!password) {
            formIsValid = false;
            // setValidator("Please insert your password.");
            message.error("Please insert your password.");
            return formIsValid;
        }

        return formIsValid;
    }

    function login() {
        setUserLoading(true)
        //dispacth action to login and get users data
        fetchUser({
            email: email,
            password: sha512(password)
        });

    }

    return (
        <div>
            <ContentHeader title="Login" />
            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                        <Col span={4}></Col>
                        <Col span={16}>
                            <form onSubmit={(e) => handleLogin(e)}>
                                <Row>
                                    <Col span={24}>
                                        <label>Email</label>
                                        <Input className="text-area-" type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={24}>
                                        <label>Password</label>
                                        <Input.Password className="text-area-" placeholder="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit">Login</Button>
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <p className="error-p">{user.error}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>

                                        {user.loading ? <Loader title="Loading..." /> : null}

                                    </Col>
                                </Row>
                            </form>
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                </div>
            </Content>
        </div>
    )
}



const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: (obj) => dispatch(fetchUser(obj)),
        setUserLoading: (bool) => dispatch(setUserLoading(bool)),
        fetchUserFaliur: (str) => dispatch(fetchUserFaliur(str)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
