export const setReportDate = (date) => {
    return {
        type: "SET_REPORT_DATE",
        payload: date
    }
}

export const setMountainRegion = (id) => {
    return {
        type: "SET_MONTAIN_REGION",
        payload: id
    }
}

export const setRegionalOutlook = (str) => {
    return {
        type: "SET_REGIONAL_OUTLOOK",
        payload: str
    }
}

export const setAlpineHazards = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_ALPINE_HAZARDS",
        payload: obj
    }
}


export const setSubAlpineChange = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_SUB_ALPINE_HAZARDS",
        payload: obj
    }
}

export const setAlpineHazardEmpty = () => {
    return {
        type: "SET_ALPINE_HAZARDS_EMPTY",
        payload: ""
    }
}

export const setSubAlpineHazardEmpty = () => {
    return {
        type: "SET_SUB_ALPINE_HAZARDS_EMPTY",
        payload: ""
    }
}



export const setTravelAndTerrainAdvice = (str) => {
    return {
        type: "SET_TRAVEL_AND_TERRAIN_ADVICE",
        payload: str
    }
}

export const setPrimaryHazard = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_PRIMARY_HAZARD",
        payload: obj
    }
}

export const setSecondaryHazard = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_SECONDARY_HAZARD",
        payload: obj
    }
}

export const setTertiaryHazard = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_TERTIARY_HAZARD",
        payload: obj
    }
}

export const setQuaternaryHazard = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_QUATERNARY_HAZARD",
        payload: obj
    }
}

export const setMoreDetails = (obj) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_MORE_DETAILS",
        payload: obj
    }
}

export const setNewReportLoading = (bool) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_NEW_REPORT_LOADING_TRUE",
        payload: bool
    }
}

export const setNewReportEmpty = () => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_NEW_REPORT_EMPTY",
        payload: ''
    }
}


export const setReportEditData = (obj) => {
    // console.log('alpine',obj);
    return {
        type: "SET_REPORT_EDIT_DATA",
        payload: obj
    }
}

export const setReportCreateData = (obj) => {
    // console.log('alpine',obj);
    return {
        type: "SET_REPORT_CREATE_DATA",
        payload: obj
    }
}


export const setYesterdayStatus = (id) => {
    // console.log('alpine',obj[0]);
    return {
        type: "SET_YESTERDAY_STATUS",
        payload: id
    }
}

