import React from 'react'
import { Row, Col } from 'antd';

function MoreDetails({ hazard_summary, snowpack_summary, weather_summary, forecast_confidence, forecast_confidence_summary }) {
    return (
        <div className="more-details-section">
            <Row>
                <Col md={24} sm={24}>
                    <h5>Hazard Summary</h5>
                    <p>
                        {hazard_summary}
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={24} sm={24}>
                    <h5>Snowpack Summary</h5>
                    <p>
                        {snowpack_summary}
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={24} sm={24}>
                    <h5>Weather Summary</h5>
                    <p>
                        {weather_summary}
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={24} sm={24}>
                    <h5>Confidence</h5>
                    <h5>{forecast_confidence}</h5>
                    <p>
                        {forecast_confidence_summary}
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default MoreDetails
