import React, { useEffect, useState } from 'react'
import { Layout } from 'antd';
import { Input, Button, Spin, message } from 'antd';
import { Row, Col } from 'antd';

import { connect } from 'react-redux'
import { fetchUserSuccess, fetchUserFaliur, setLogout, setUserLoading } from '../../redux'
import ContentHeader from '../../components/ContentHeader';

import axios from 'axios'
import { apiBaseUrl } from '../../Url'

const { Content } = Layout;
const sha512 = require("js-sha512");

function EditPassword({ fetchUserSuccess, fetchUserFaliur, setUserLoading, user, history, setLogout }) {

    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [rePassword, setRePassword] = useState("")

    useEffect(() => {

        if (!user.details.email) {
            history.push("/login")
        } 

    }, [user, history]);

    function handleLogin(e) {
        e.preventDefault();

        if (handleValidation()) {
            register();
        }
    }

    function handleValidation() {
        let formIsValid = true;

        //password
        if (!password) {
            formIsValid = false;
            message.error('Please insert your old password.');
            return formIsValid;
        }

        //newPassword
        if (!newPassword) {
            formIsValid = false;
            message.error("Please insert your new password.");
            return formIsValid;
        }

        var lowerCaseLetters = /[a-z]/g;
        if (!newPassword.match(lowerCaseLetters)) {
            formIsValid = false;
            message.error("New password must include at least one lowercase letter");
            return formIsValid;
        }

        var upperCaseLetters = /[A-Z]/g;
        if (!newPassword.match(upperCaseLetters)) {
            formIsValid = false;
            message.error("New password must include at least one uppercase letter");
            return formIsValid;
        }

        var numbers = /[0-9]/g;
        if (!newPassword.match(numbers)) {
            formIsValid = false;
            message.error("New password must include at least one number.");
            return formIsValid;
        }

        if (newPassword.length < 8) {
            formIsValid = false;
            message.error("New password must be a minimum of 8 characters.");
            return formIsValid;
        }

        if (!rePassword) {
            formIsValid = false;
            message.error("Please re-type your password.");
            return formIsValid;
        }

        if (newPassword!== rePassword) {
            formIsValid = false;
            message.error("Confirm password field not match with new password field.");
            return formIsValid;
        }

        return formIsValid;
    }

    function register() {
        setUserLoading(true)
        const obj = { password: sha512(password), newPassword: sha512(newPassword) }
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/edit-password",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { obj }
            })
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    message.success("Password updated successfully.")
                    setUserLoading(false)
                    setPassword("")
                    setRePassword("")
                    setNewPassword("")
                } else if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                }
                else {
                    message.error(response.data.message)
                    setUserLoading(false)
                }
            })
            .catch(error => {
                const errorMsg = error.message
                message.error(errorMsg)
                setUserLoading(false)
            })

    }

    return (
        <div>
            <ContentHeader title="Edit Password" />
            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" >
                    <Row>
                        <Col md={2} sm={24}></Col>
                        <Col md={20} sm={24}>
                            <form onSubmit={(e) => handleLogin(e)}>
                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Old Password</label>
                                        <Input.Password className="text-area-" type="text" placeholder="Old Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>New Password</label>
                                        <Input.Password className="text-area-" type="text" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Re-Type New Password</label>
                                        <Input.Password className="text-area-" type="text" placeholder="Re-Type New Password" onChange={(e) => setRePassword(e.target.value)} value={rePassword} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit">Edit</Button>
                                        {user.loading ? <>&nbsp;<Spin size="large" /> </> : null}
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Col>
                        <Col md={2} sm={24}></Col>
                    </Row>
                </div>
            </Content>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserSuccess: (obj) => dispatch(fetchUserSuccess(obj)),
        setUserLoading: (bool) => dispatch(setUserLoading(bool)),
        fetchUserFaliur: (msg) => dispatch(fetchUserFaliur(msg)),
        setLogout: () => dispatch(setLogout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
