import React from 'react'
import { Spin } from 'antd';

function Loader() {
    return (
        <div className="loader">
            <center>
            <Spin size="large" />
            <br />
            <label>Loading...</label>
            </center>
        </div>
    )
}

export default Loader
