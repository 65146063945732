import React from 'react';
import { Layout, Row, Col } from 'antd';
import ContentHeader from './components/ContentHeader';
import { baseUrl } from './Url'

const { Content } = Layout;

function Embeddedcodepreiview() {


    return (
        <div>
            <ContentHeader title="View Embedded Report" />
            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" >


                <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe class="responsive-iframe" src="https://reports.mountainsafetycollective.org/embedded-report-summary-large" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <iframe className="responsive-iframe-large" src={baseUrl + "embedded-report-summary-large"} title="MSC summarized report" scrolling="no"></iframe>
                    </Row>


                    <br />
                    <br />


                    {/* <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe class="responsive-iframe" src="https://reports.mountainsafetycollective.org/embedded-report-summary" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <iframe className="responsive-iframe" src="https://reports.mountainsafetycollective.org/embedded-report-summary" title="MSC summarized report" scrolling="no"></iframe>
                    </Row>


                    <br />
                    <br /> */}

                    {/* <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe class="responsive-iframe-1" src="https://reports.mountainsafetycollective.org/embedded-report-summary-front-and-dividing-range" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <iframe className="responsive-iframe-1" src={baseUrl + "embedded-report-summary-front-and-dividing-range"}   title="MSC summarized report" scrolling="no"></iframe>
                    </Row>


                    <br />
                    <br />

                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe class="responsive-iframe-2" src="https://reports.mountainsafetycollective.org/embedded-report-summary-main-range" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <iframe className="responsive-iframe-2" src={baseUrl + "embedded-report-summary-main-range"} title="MSC summarized report" scrolling="no"></iframe>
                    </Row> */}




                    {/* <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe src="https://reports.mountainsafetycollective.org/embedded-report-summary/2021-06-11" height="600" width="600" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <iframe src="https://reports.mountainsafetycollective.org/embedded-report-summary/2021-06-11" height="600" width="600"
                                title="MSC summarized report" scrolling="no"></iframe>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe src="https://reports.mountainsafetycollective.org/embedded-report-summary/2021-06-11" height="405" width="405" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <iframe src="https://reports.mountainsafetycollective.org/embedded-report-summary/2021-06-11" height="405" width="405" title="MSC summarized report" scrolling="no"></iframe>
                        </Col>
                    </Row>

                    <br />
                    <br />
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div >
                                <p>
                                    {'<iframe src="https://reports.mountainsafetycollective.org/embedded-report-summary/2021-06-11" height="300" width="300" title="MSC summarized report" scrolling="no"></iframe>'}
                                </p>
                            </div>

                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <iframe src="https://reports.mountainsafetycollective.org/embedded-report-summary/2021-06-11" height="300" width="300" title="MSC summarized report" scrolling="no"></iframe>
                        </Col>
                    </Row> */}
                </div>
            </Content>
        </div>
    );
}




export default Embeddedcodepreiview;
