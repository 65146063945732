import React from 'react';
import { Layout, Row, Col, DatePicker } from 'antd';
import ContentHeader from './components/ContentHeader';
import moment from 'moment'

const { Content } = Layout;

function onDateChange(e) {
    // const date = moment(new Date(e)).format('YYYY-MM-DD')

    const date  = moment.utc(new Date(e)).local().format('YYYY-MM-DD');
    alert(date)
}

function Test() {


    return (
        <div>
            <ContentHeader title="Test" />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                    </Row>
                    <br />

                    <Row>
                        <Col span={24}>
                            <h2>Test</h2>
                            <DatePicker onChange={e => onDateChange(e)}
                                // value={moment(date, 'YYYY/MM/DD')}
                                // defaultValue={moment(date, 'DD/MM/YYYY')} 
                                format={'DD/MM/YYYY'} />
                        </Col>
                    </Row>
                </div>
            </Content>
        </div>
    );
}



export default Test;
