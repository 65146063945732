import React from 'react'
import { Col, Input, Row, Radio } from 'antd';
const { TextArea } = Input;


function Quaternary({ setQuaternaryHazard, newReport, maxHazardsRadios, hazardCharacteristicsRadios, hazardElevationRadios, hazardAspectsRadios, avalancheSizesRadios, hazardLikelihoodRadios }) {

    const onHazardChange = (value) => {
        const arr = value.split("_")
        const hazardId = parseInt(arr[0])
        const elevationId = parseInt(arr[1])
        // console.log(hazardId);
        setQuaternaryHazard({ ...newReport.quaternaryHazard, hazardId: hazardId, elevationId: elevationId, characteristicId: 0 })
    }

    return (
        <div>
            <Row>
                <Col md={12} sm={24}>
                    <h3 className="report-form-h3">Quaternary Hazard</h3>
                    <Radio.Group onChange={e => onHazardChange(e.target.value)} value={newReport.quaternaryHazard.hazardId + "_" + newReport.quaternaryHazard.elevationId}>
                        {maxHazardsRadios}
                    </Radio.Group>
                </Col>
            </Row>
            <br />
            <br />
            {
                (newReport.quaternaryHazard.hazardId !== 0)
                    ?
                    (newReport.quaternaryHazard.hazardId !== 10)
                        ?

                        <div>
                            <Row>
                                <Col md={24} sm={24}>
                                    <h3 className="report-form-h3">Avalanche danger</h3>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col md={8} sm={24}>
                                            <label className="report-form-lbl">Hazard characteristics</label>
                                            <br />
                                            <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, characteristicId: e.target.value })} value={newReport.quaternaryHazard.characteristicId} style={{ marginTop: 5 }}>
                                                {hazardCharacteristicsRadios}
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    <br />

                                    {
                                        (newReport.quaternaryHazard.characteristicId !== 9)
                                            ?
                                            <Row>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard elevation</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, elevationId: e.target.value })} value={newReport.quaternaryHazard.elevationId} style={{ marginTop: 5 }}>
                                                        {hazardElevationRadios}
                                                    </Radio.Group>
                                                </Col>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard aspect</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, aspectId: e.target.value })} value={newReport.quaternaryHazard.aspectId} style={{ marginTop: 5 }}>
                                                        {hazardAspectsRadios}
                                                    </Radio.Group>
                                                </Col>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard likelihood</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, likelihoodId: e.target.value })} value={newReport.quaternaryHazard.likelihoodId} style={{ marginTop: 5 }}>
                                                        {hazardLikelihoodRadios}
                                                    </Radio.Group>
                                                </Col>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Avalanche size</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, avalancheSizesId: e.target.value })} value={newReport.quaternaryHazard.avalancheSizesId} style={{ marginTop: 5 }}>
                                                        {avalancheSizesRadios}
                                                    </Radio.Group>
                                                </Col>
                                            </Row>

                                            :

                                            <Row>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard elevation</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, elevationId: e.target.value })} value={newReport.quaternaryHazard.elevationId} style={{ marginTop: 5 }}>
                                                        {hazardElevationRadios}
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                    }


                                    <br />
                                    <Row>
                                        <Col md={24} sm={24}>
                                            <label className="report-form-lbl">Summary</label>
                                            <br />
                                            <TextArea className="text-area-" rows={4} onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, summary: e.target.value })} value={newReport.quaternaryHazard.summary} style={{ marginTop: 5 }} />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </div>
                        :

                        <div>
                            <Row>
                                <Col md={24} sm={24}>
                                    <h3 className="report-form-h3">Avalanche danger</h3>
                                    <Row>
                                        <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                            <label className="report-form-lbl">Hazard elevation</label>
                                            <br />
                                            <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, elevationId: e.target.value })} value={newReport.quaternaryHazard.elevationId} style={{ marginTop: 5 }}>
                                                {hazardElevationRadios}
                                            </Radio.Group>
                                        </Col>
                                        <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                            <label className="report-form-lbl">Hazard aspect</label>
                                            <br />
                                            <Radio.Group onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, aspectId: e.target.value })} value={newReport.quaternaryHazard.aspectId} style={{ marginTop: 5 }}>
                                                {hazardAspectsRadios}
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={24} sm={24}>
                                            <label className="report-form-lbl">Summary</label>
                                            <br />
                                            <TextArea className="text-area-" rows={4} onChange={e => setQuaternaryHazard({ ...newReport.quaternaryHazard, summary: e.target.value })} value={newReport.quaternaryHazard.summary} style={{ marginTop: 5 }} />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </div>
                    :
                    null
            }
        </div>
    )
}


export default Quaternary
