import React from 'react'
import { Layout } from 'antd';
import { useLocation } from "react-router-dom";
const { Footer } = Layout;


function FooterHQ() {

    const pathname = useLocation().pathname;
    const pathnameSplitArray = pathname.split("/")

    return (
        <>
        {
            (pathnameSplitArray[1] !== 'embedded-report-summary'
            && pathnameSplitArray[1] !== 'embedded-report-summary-front-and-dividing-range'
            && pathnameSplitArray[1] !== 'embedded-report-summary-main-range'
            && pathnameSplitArray[1] !== 'embedded-report-summary-large'
            && pathnameSplitArray[1] !== 'ddddddd')
            ?
            <Footer style={{ textAlign: 'center', color: "#fff" }}>MSC HQ</Footer>
            :
            null
        }
        </>
    )
}
export default FooterHQ