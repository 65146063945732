import React, { useState, useEffect } from 'react'
import { Layout } from 'antd';
import { Input, Button, message } from 'antd';
import { Row, Col } from 'antd';

import { connect } from 'react-redux'
import { registerUser, setUserLoading, fetchUserFaliur } from './redux'
import ContentHeader from './components/ContentHeader';

const { Content } = Layout;
const sha512 = require("js-sha512");

function Register({ registerUser, setUserLoading, user, history, fetchUserFaliur }) {
    const [fname, setFname] = useState("")
    const [lname, seLname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")

    useEffect(() => {
        if (user.details.email) {
            history.push("/")
        }

    }, [user, history]);

    function handleLogin(e) {
        e.preventDefault();

        if (handleValidation()) {
            register();
        }
    }

    function handleValidation() {
        let formIsValid = true;

        if (!fname) {
            formIsValid = false;
            message.error('Please insert your first name.');
            return formIsValid;
        }

        if (!lname) {
            formIsValid = false;
            message.error('Please insert your last name.');
            return formIsValid;
        }


        //email
        if (!email) {
            formIsValid = false;
            message.error('Please insert your email address.');
            return formIsValid;
        }

        if (typeof email !== "undefined") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                message.error("Email is not valid.");
                return formIsValid;
            }
        }

        //password
        if (!password) {
            formIsValid = false;
            message.error("Please insert your password.");
            return formIsValid;
        }

        var lowerCaseLetters = /[a-z]/g;
        if (!password.match(lowerCaseLetters)) {
            formIsValid = false;
            message.error("Password must include at least one lowercase letter");
            return formIsValid;
        }

        var upperCaseLetters = /[A-Z]/g;
        if (!password.match(upperCaseLetters)) {
            formIsValid = false;
            message.error("Password must include at least one uppercase letter");
            return formIsValid;
        }

        var numbers = /[0-9]/g;
        if (!password.match(numbers)) {
            formIsValid = false;
            message.error("Password must include at least one number.");
            return formIsValid;
        }

        if (password.length < 8) {
            formIsValid = false;
            message.error("Password must be a minimum of 8 characters.");
            return formIsValid;
        }

        if (!rePassword) {
            formIsValid = false;
            message.error("Please re-type your password.");
            return formIsValid;
        }

        if (password !== rePassword) {
            formIsValid = false;
            message.error("Confirm password field not match with password field.");
            return formIsValid;
        }


        return formIsValid;
    }

    function register() {
        setUserLoading(true)
        //dispacth action to login and get users data
        registerUser({
            fname,
            lname,
            phone,
            email: email,
            password: sha512(password)
        });

    }

    return (
        <div>
            <ContentHeader title="Register" />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                        <Col md={2} sm={24}></Col>
                        <Col md={20} sm={24}>
                            <form onSubmit={(e) => handleLogin(e)}>

                                <br />
                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>First Name</label>
                                        <Input className="text-area-" type="text" placeholder="First Name" onChange={(e) => setFname(e.target.value)} value={fname} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Last Name</label>
                                        <Input className="text-area-"  type="text" placeholder="Last Name" onChange={(e) => seLname(e.target.value)} value={lname} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Email Address</label>
                                        <Input className="text-area-" type="text" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Phone Number</label>
                                        <Input className="text-area-" type="text" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Password</label>
                                        <Input.Password className="text-area-" placeholder="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Re-Type Password</label>
                                        <Input.Password className="text-area-" type="text" placeholder="Last Name" onChange={(e) => setRePassword(e.target.value)} value={rePassword} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit">Register</Button>
                                        {/* {user.loading ? <>&nbsp;<Spin size="large" /> </> : null} */}
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <p className="error-p">{user.error}</p>
                                        <p className="success-p">{user.success}</p>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                        <Col md={2} sm={24}></Col>
                    </Row>
                </div>
            </Content>
        </div>
    )
}



const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerUser: (obj) => dispatch(registerUser(obj)),
        fetchUserFaliur: (str) => dispatch(fetchUserFaliur(str)),
        setUserLoading: (bool) => dispatch(setUserLoading(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
