import axios from 'axios'
import { apiBaseUrl } from '../../Url'
import {setLogout} from '../user/userActions'

export const fetchRequest = () => {
    return {
        type: "FETCH_GLOBAL_REQUEST"
    }
}

export const fetchGlobalSuccess = (data) => {
    return {
        type: "FETCH_GLOBAL_SUCCESS",
        payload: data
    }
}

export const fetchGlobalFaliure = (error) => {
    return {
        type: "FETCH_GLOBAL_FALIUR",
        payload: error
    }
}


export const fetchGlobal = () => {
    return (dispatch) => {
        dispatch(fetchRequest());
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "global/get_backend_data",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { user: 'name' }
            })
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    dispatch(fetchGlobalSuccess(response.data))
                }else if(response.data.status === 401){
                    setLogout(localStorage.getItem('sessionKey'))
                }
                else {
                    dispatch(fetchGlobalFaliure(response.data.message))
                }
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchGlobalFaliure(errorMsg))
            })

    }
}



export const setGlobalEmpty = () => {
    return {
        type: "SET_GLOBAL_EMPTY",
        payload: ""
    }
}


export const changeMontainRangeSpringCondtion = (obj) => {
    return {
        type: "CHANGE_MONTAIN_RANGE_SPRING_CONDTION",
        payload: obj
    }
}