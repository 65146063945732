import React, {useEffect} from 'react';

import { connect } from 'react-redux'
import { setLogout } from './redux'

function Logout(props) {
    useEffect(() => {
        const session_key = localStorage.getItem('sessionKey');
        props.setLogout(session_key)
        props.history.push("/")
    }, [props]);

    return (
        <div>
            
        </div>
    )
}



const mapDispatchToProps = (dispatch) => {
    return {
        setLogout: (key) => dispatch(setLogout(key)),
    }
}

export default connect(null, mapDispatchToProps)(Logout);
