import React, { useEffect } from 'react';
import { Layout, Row, Col, DatePicker } from 'antd';
import ContentHeader from './components/ContentHeader';
import moment from 'moment'
import { connect } from 'react-redux'
import { setReportLoading, setReports } from './redux'
import axios from 'axios'
import { apiBaseUrl } from './Url'
import Loader from './components/Loader';
import Report from './components/Report';


const { Content } = Layout;

function Home({ setReportLoading, setReports, report, history, user }) {
  useEffect(() => {

    if (!user.details.type) {
      history.push('/login')
    }

  }, [user.details.type, history]);

  useEffect(() => {
    setReportLoading(true)
    axios(
      {
        method: 'POST',
        url: apiBaseUrl + "report/get_view_data_by_date",
        data: { date: moment(new Date()).format("YYYY-MM-DD") }
      })
      .then(response => {
        // console.log(response)
        if (response.data.status !== 0) {
          setReports(response.data.reports)
        } else {
          setReports([])
        }
        setReportLoading(false)
      })
      .catch(error => {
        const errorMsg = error.message
        setReportLoading(false)
        console.log(errorMsg);
        // showError(errorMsg)
      })

  }, [setReportLoading, setReports]);

  function onChange(date, dateString) {
    // console.log(dateString);
    setReportLoading(true)
    axios(
      {
        method: 'POST',
        url: apiBaseUrl + "report/get_view_data_by_date",
        data: { date: moment(new Date(date)).format('YYYY-MM-DD') }
      })
      .then(response => {
        // console.log(response)
        if (response.data.status !== 0) {
          setReports(response.data.reports)
        } else {
          setReports([])
        }
        setReportLoading(false)
      })
      .catch(error => {
        const errorMsg = error.message
        setReportLoading(false)
        console.log(errorMsg);
        // showError(errorMsg)
      })
  }
  return (
    <div>
      <ContentHeader title="Home" />
      <Content style={{ margin: '24px 16px 0' }}>
        <div className="site-layout-background">
          <Row>



            <Col md={5} sm={12}>
            </Col>


            <Col md={14} sm={24}>
              <Row>
                <Col md={24} sm={24} style={{ marginBottom: 20 }}>
                  <center>
                    <DatePicker onChange={onChange} defaultValue={moment(new Date(), 'DD/MM/YYYY')} format={'DD/MM/YYYY'}/>
                  </center>
                </Col>
              </Row>

              {(report.reports.length > 0)
                ?
                report.reports.map(function (item, i) {
                  return (
                    <Row key={i}>
                      <center>
                        <Report item={item} />
                      </center>
                    </Row>
                  )
                })
                :
                <Row >
                  <Col md={24} sm={24} style={{ marginBottom: 20 }}>
                    <center>
                      <p style={{ marginTop: 50 }}>No Report Found.</p>
                    </center>
                  </Col>
                </Row>
              }
            </Col>

            <Col md={5} sm={12}>
            </Col>
          </Row>
        </div>
      </Content>
      {(report.loading ? <Loader title="Loading..." /> : null)}
    </div>
  );
}




const mapStateToProps = state => {
  return {
    report: state.report,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReportLoading: (bool) => dispatch(setReportLoading(bool)),
    setReports: (obj) => dispatch(setReports(obj)),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);
