const initState = {
    loading: false,
    error: "",
    reports: [
        {
            report: {
                id: 1,
                date: '',
                region: '',
                regional_outlook: '',
                travel_and_terrain_advice: '',
                hazard_summary: '',
                snowpack_summary: '',
                weather_summary: '',
                forecast_confidence: '',
                forecast_confidence_summary: '',
                override_alpine_rating: 0,
                override_sub_alpine_rating: 0,
                status: '',
                created_by: ''
            },
            // report: {
            //     id: 1,
            //     date: '2021-06-04',
            //     region: 'Front Range',
            //     regional_outlook: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
            //     travel_and_terrain_advice: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
            //     hazard_summary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
            //     snowpack_summary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
            //     weather_summary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
            //     forecast_confidence: 'Moderate',
            //     forecast_confidence_summary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
            //     status: 'Un-Published',
            //     created_by: ''
            // },
            alpine_hazards: [
                {
                    report_id: 1,
                    id: 14,
                    category: 'Avalanche danger',
                    name: 'High avalanche danger',
                    info: '',
                    rating: 6
                },
            ],
            sub_alpine_hazards: [
                {
                    report_id: 1,
                    id: 12,
                    category: 'Avalanche danger',
                    name: 'Moderate avalanche danger',
                    info: '',
                    rating: 2
                },
            ],
            categorisation: [
                {
                    id: 17,
                    report_id: 1,
                    hazard_id: 14,
                    type: 'Primary',
                    characteristic_id: 1,
                    elevation_id: 3,
                    aspect_id: 1,
                    avalanche_sizes_id: 3,
                    likelihood_id: 5,
                    summary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
                    hazard: 'High avalanche danger',
                    characteristic: 'Storm slab',
                    elevation: 'Alpine + Subalpine',
                    aspect: 'N',
                    avalanche_size: 'Large',
                    likelihood: 'Likely'
                }
            ]
        }
    ]
}


const reportReducer = (state = initState, action) => {
    switch (action.type) {

        case "SET_REPORT_LOADING":
            return {
                ...state,
                loading: action.payload
            }

        case "SET_REPORTS":
            return {
                ...state,
                reports: action.payload
            }

        default:
            return state
    }

}

export default reportReducer;