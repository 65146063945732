import React, { useState, useEffect } from 'react'

function RangeImg({ alpine_hazards, sub_alpine_hazards, region }) {
    const [total_alpine_rating, set_total_alpine_rating] = useState(0)
    const [total_sub_alpine_rating, set_total_sub_alpine_rating] = useState(0)
    const [range_img, set_range_img] = useState("")

    const img_path = "../images/";

    useEffect(() => {

        //according to new requirement on 2022-03-25 
        // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
        const alpineExtra = alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');
        const subAlpineExtra = sub_alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');


        var alpine_tot = 0;
        for (var i = 0; i < alpine_hazards.length; i++) {
            alpine_tot = alpine_tot + alpine_hazards[i].rating
        }

        // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
        if (alpineExtra) {
            if (alpine_tot < 4) {
                alpine_tot = 4
            }
        }
        set_total_alpine_rating(alpine_tot)

        var sub_alpine_tot = 0;
        for (i = 0; i < sub_alpine_hazards.length; i++) {
            sub_alpine_tot = sub_alpine_tot + sub_alpine_hazards[i].rating
        }

         // If there is a moderate or considerable avalanche danger, then the report should atleast show ‘Extra Caution Recommended' even if no other dangers are present.
         if (subAlpineExtra) {
            if (sub_alpine_tot < 4) {
                sub_alpine_tot = 4
            }
        }
        set_total_sub_alpine_rating(sub_alpine_tot)


    }, [alpine_hazards, total_sub_alpine_rating, sub_alpine_hazards])


    useEffect(() => {
            if (total_alpine_rating <= 3 && total_sub_alpine_rating <= 3) {
                set_range_img("summary_01.png")
            }

            if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && total_sub_alpine_rating <= 3) {
                set_range_img("summary_02.png")
            }

            if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
                set_range_img("summary_03.png")
            }

            if (total_alpine_rating >= 8 && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
                set_range_img("summary_04.png")
            }

            if (total_alpine_rating >= 8 && total_sub_alpine_rating >= 8) {
                set_range_img("summary_05.png")
            }

            if (total_alpine_rating >= 8 && total_sub_alpine_rating <= 3) {
                set_range_img("summary_06.png")
            }

            if (total_alpine_rating <= 3 && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
                set_range_img("summary_07.png")
            }

            if (total_alpine_rating <= 3 && total_sub_alpine_rating >= 8) {
                set_range_img("summary_08.png")
            }

            if ((total_alpine_rating <= 7 && total_alpine_rating >= 4) && total_sub_alpine_rating >= 8) {
                set_range_img("summary_09.png")
            }

    }, [total_alpine_rating, total_sub_alpine_rating, region, alpine_hazards, sub_alpine_hazards])


    // useEffect(() => {
    //     //according to new requirement on 2022-03-25 
    //     // If there is a moderate or considerable avalanche danger, then the report should always show ‘Extra Caution Recommended' even if no other dangers are present.

    //     const alpineExtra = alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');
    //     const subAlpineExtra = sub_alpine_hazards.some((item) => item.name === 'Considerable avalanche danger' || item.name === 'Moderate avalanche danger');

    //     //if alpine or subalpine  selected considerbale or moderate avalanche danger
    //     if (alpineExtra || subAlpineExtra) {

    //         if (alpineExtra && total_sub_alpine_rating <= 3) {
    //             set_range_img("summary_02.png")
    //         }

    //         if (alpineExtra && (total_sub_alpine_rating <= 7 && total_sub_alpine_rating >= 4)) {
    //             set_range_img("summary_03.png")
    //         }

    //         if (alpineExtra && total_sub_alpine_rating >= 8) {
    //             set_range_img("summary_09.png")
    //         }

    //         if (subAlpineExtra && total_alpine_rating <= 3) {
    //             set_range_img("summary_07.png")
    //         }

    //         if (subAlpineExtra && (total_alpine_rating <= 7 && total_alpine_rating >= 4)) {
    //             set_range_img("summary_03.png")
    //         }


    //         if (subAlpineExtra && total_alpine_rating >= 8) {
    //             set_range_img("summary_04.png")
    //         }

    //         if (alpineExtra && subAlpineExtra) {
    //             set_range_img("summary_03.png")
    //         }
    //     }

    // }, [total_alpine_rating, total_sub_alpine_rating, region, alpine_hazards, sub_alpine_hazards])



    return (
        <div>
            <img alt={range_img} src={img_path + range_img} />
        </div>
    )
}

export default RangeImg
