const initState = {
    loading: false,
    error: "",
    success: "",
    details: {}
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_USER_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "FETCH_USER_SUCCESS":
            return {
                ...state,
                loading: false,
                details: action.payload,
                error: ""
            }
        case "FETCH_USER_FALIUR":
            return {
                ...state,
                loading: false,
                details: {},
                success: "",
                error: action.payload
            }

        case "CREATE_USER_FALIUR":
            return {
                ...state,
                loading: false,
            }

        case "USER_CREATED_SUCCESS":
            return {
                ...state,
                loading: false,
                details: {},
                error: "",
                success: action.payload
            }

        case "SET_USER_EMPTY":
            return {
                ...state,
                loading: false,
                details: {},
                error: ""
            }

        case "LOGOUT":
            return {
                ...state,
                loading: false,
                details: {},
                error: ''
            }


        default:
            return state
    }

}

export default userReducer;