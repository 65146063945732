import axios from 'axios'
import { apiBaseUrl } from '../../Url'
import {setGlobalEmpty } from '../global/globalActions'

export const fetchUser = (obj) => {
    const email = obj.email
    const password = obj.password

    return (dispatch) => {
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/login",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { email, password }
            })
            .then(response => {
                // console.log(response)
                if (response.data.status === 1) {
                    localStorage.setItem('sessionKey', response.data.auth_key);
                    const user = {
                        "fname": response.data.fname,
                        "lname": response.data.lname,
                        "email": response.data.email,
                        "phone": response.data.phone,
                        "type": response.data.type,
                    }
                    dispatch(fetchUserSuccess(user))
                }
                else {
                    dispatch(fetchUserFaliur(response.data.message))
                }
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchUserFaliur(errorMsg))
            })

    }
}

export const fetchUserSuccess = (data) => {
    return {
        type: "FETCH_USER_SUCCESS",
        payload: data
    }
}


export const fetchUserFaliur = (error) => {
    return {
        type: "FETCH_USER_FALIUR",
        payload: error
    }
}

export const createUserFaliur = (error) => {
    return {
        type: "CREATE_USER_FALIUR",
        payload: error
    }
}


export const createUserSuccess = (msg) => {
    return {
        type: "USER_CREATED_SUCCESS",
        payload: msg
    }
}

export const setUserLoading = (status) => {
    return {
        type: "SET_USER_LOADING",
        payload: status
    }
}



export const fetchUserByID = (key) => {
    return (dispatch) => {

        dispatch(setUserLoading(true));

        axios.post(apiBaseUrl + "user/get_by_key", { auth_key: key })
            .then(response => {
                // console.log(response)
                if (response.data.status === 1) {

                    const user = {
                        "fname": response.data.fname,
                        "lname": response.data.lname,
                        "email": response.data.email,
                        "phone": response.data.phone,
                        "type": response.data.type,
                    }
                    dispatch(fetchUserSuccess(user))
                    dispatch(setUserLoading(false));
                } else {
                    const errorMsg = response.data.message
                    dispatch(fetchUserFaliur(errorMsg))
                    dispatch(setUserLoading(false));
                }
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchUserFaliur(errorMsg))
                dispatch(setUserLoading(false));
            })
    }
}


export const setLogout = (key) => {
    return (dispatch) => {
        dispatch(setUserLoading(true));
        axios.post(apiBaseUrl + "user/logout", { auth_key: key })
            .then(response => {
                localStorage.removeItem('sessionKey');
                dispatch(logout())
                dispatch(setUserLoading(false));
                dispatch(setGlobalEmpty())
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(setUserLoading(false));
                dispatch(fetchUserFaliur(errorMsg))
            })
    }
}



export const logout = () => {
    return {
        type: "LOGOUT"
    }
}



export const registerUser = (obj) => {

    return (dispatch) => {
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/register",
                data: { obj }
            })
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    
                    dispatch(createUserSuccess("Successfully registered. You will be notified when the admin approves your account."))
                }
                else {
                    dispatch(fetchUserFaliur(response.data.message))
                }
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchUserFaliur(errorMsg))
            })

    }
}

