import React from 'react'
import { Col, Input, Row, Radio } from 'antd';
const { TextArea } = Input;


function Tertiary({ setTertiaryHazard, newReport, maxHazardsRadios, hazardCharacteristicsRadios, hazardElevationRadios, hazardAspectsRadios, avalancheSizesRadios, hazardLikelihoodRadios }) {

    const onHazardChange = (value) => {
        const arr = value.split("_")
        const hazardId = parseInt(arr[0])
        const elevationId = parseInt(arr[1])
        // console.log(hazardId);
        setTertiaryHazard({ ...newReport.tertiaryHazard, hazardId: hazardId, elevationId: elevationId, characteristicId: 0 })
    }

    var selectedHazard = newReport.tertiaryHazard.hazardId + "_" + newReport.tertiaryHazard.elevationId

    if (newReport.tertiaryHazard.elevationId === 3) {
        // selectedHazard = newReport.tertiaryHazard.hazardId + "_" + 1
        for (var i = 0; i < maxHazardsRadios.length; i++) {
            var value = maxHazardsRadios[i].props.children[0].props.value
            var arr = value.split("_");
            if (parseInt(arr[0]) === newReport.tertiaryHazard.hazardId) {
                selectedHazard = value;
                break;
            }
        }
    }


    return (
        <div>
            <Row>
                <Col md={12} sm={24}>
                    <h3 className="report-form-h3">Tertiary Hazard</h3>
                    <Radio.Group onChange={e => onHazardChange(e.target.value)} value={selectedHazard}>
                        {maxHazardsRadios}
                    </Radio.Group>
                </Col>
            </Row>
            <br />
            <br />
            {
                (newReport.tertiaryHazard.hazardId !== 0)
                    ?
                    (newReport.tertiaryHazard.hazardId !== 10)
                        ?

                        <div>
                            <Row>
                                <Col md={24} sm={24}>
                                    <h3 className="report-form-h3">Avalanche danger</h3>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col md={8} sm={24}>
                                            <label className="report-form-lbl">Hazard characteristics</label>
                                            <br />
                                            <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, characteristicId: e.target.value })} value={newReport.tertiaryHazard.characteristicId} style={{ marginTop: 5 }}>
                                                {hazardCharacteristicsRadios}
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    <br />

                                    {
                                        (newReport.tertiaryHazard.characteristicId !== 9)
                                            ?
                                            <Row>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard elevation</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, elevationId: e.target.value })} value={newReport.tertiaryHazard.elevationId} style={{ marginTop: 5 }}>
                                                        {hazardElevationRadios}
                                                    </Radio.Group>
                                                </Col>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard aspect</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, aspectId: e.target.value })} value={newReport.tertiaryHazard.aspectId} style={{ marginTop: 5 }}>
                                                        {hazardAspectsRadios}
                                                    </Radio.Group>
                                                </Col>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard likelihood</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, likelihoodId: e.target.value })} value={newReport.tertiaryHazard.likelihoodId} style={{ marginTop: 5 }}>
                                                        {hazardLikelihoodRadios}
                                                    </Radio.Group>
                                                </Col>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Avalanche size</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, avalancheSizesId: e.target.value })} value={newReport.tertiaryHazard.avalancheSizesId} style={{ marginTop: 5 }}>
                                                        {avalancheSizesRadios}
                                                    </Radio.Group>
                                                </Col>
                                            </Row>

                                            :

                                            <Row>
                                                <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                                    <label className="report-form-lbl">Hazard elevation</label>
                                                    <br />
                                                    <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, elevationId: e.target.value })} value={newReport.tertiaryHazard.elevationId} style={{ marginTop: 5 }}>
                                                        {hazardElevationRadios}
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                    }


                                    <br />
                                    <Row>
                                        <Col md={24} sm={24}>
                                            <label className="report-form-lbl">Summary</label>
                                            <br />
                                            <TextArea className="text-area-" rows={4} onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, summary: e.target.value })} value={newReport.tertiaryHazard.summary} style={{ marginTop: 5 }} />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </div>
                        :

                        <div>
                            <Row>
                                <Col md={24} sm={24}>
                                    <h3 className="report-form-h3">Avalanche danger</h3>
                                    <Row>
                                        <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                            <label className="report-form-lbl">Hazard elevation</label>
                                            <br />
                                            <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, elevationId: e.target.value })} value={newReport.tertiaryHazard.elevationId} style={{ marginTop: 5 }}>
                                                {hazardElevationRadios}
                                            </Radio.Group>
                                        </Col>
                                        <Col md={24} sm={24} style={{ marginTop: 30 }}>
                                            <label className="report-form-lbl">Hazard aspect</label>
                                            <br />
                                            <Radio.Group onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, aspectId: e.target.value })} value={newReport.tertiaryHazard.aspectId} style={{ marginTop: 5 }}>
                                                {hazardAspectsRadios}
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={24} sm={24}>
                                            <label className="report-form-lbl">Summary</label>
                                            <br />
                                            <TextArea className="text-area-" rows={4} onChange={e => setTertiaryHazard({ ...newReport.tertiaryHazard, summary: e.target.value })} value={newReport.tertiaryHazard.summary} style={{ marginTop: 5 }} />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </div>
                    :
                    null
            }
        </div>
    )
}


export default Tertiary
