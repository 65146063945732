import React, { useEffect } from 'react';
import { Layout, Row, Col, Switch } from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { fetchGlobal, setLogout, changeMontainRangeSpringCondtion } from './redux'
import axios from 'axios'
import { apiBaseUrl } from './Url'

const { Content } = Layout;

function Mountainranges({ global, fetchGlobal, setLogout, history, user, changeMontainRangeSpringCondtion }) {
    useEffect(() => {
        if (global.montainRanges.length === 0) {
            fetchGlobal()
        }
    }, [fetchGlobal, global.montainRanges])

    useEffect(() => {
        if (user.details.type !== "admin") {
            history.push('/')
        }
    }, [user.details.type, history])

    function onSpringConditionChange(checked, id) {
        var flag = 0;

        if (checked) {
            flag = 1;
        } else {
            flag = 0;
        }

        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "global/change_mountain_range_spring_conditions",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { flag, id }
            })
            .then(response => {
                changeMontainRangeSpringCondtion({ flag, id })
            })
            .catch(error => {
                const errorMsg = error.message
                console.log(errorMsg);
            })
    }

    const ranges = global.montainRanges.map(function (item, i) {
        if(item.id !== 1){
        return (
            <Col lg={12} md={24} sm={24} key={i} style={{ marginBottom: 10, padding: 20 }}>
                {/* <img src={img_path + item.img} alt="mountain-ranges" style={{width: "100%", marginBottom: 10}}/> */}
                <div style={{ backgroundImage: `url(../images/${item.img}`, backgroundRepeat: "no-repeat", backgroundSize: "100%", minHeight: 170 }}>
                    <Switch
                        checkedChildren="Spring Condition Active"
                        unCheckedChildren="Spring Condition Inactive"
                        size='large'
                        style={{ float: "right", marginTop: 80, marginRight: 5 }}
                        onChange={(e) => onSpringConditionChange(e, item.id)}
                        defaultChecked={item.spring_condition === 0 ? false : true} />
                </div>
            </Col>
        )
    }else{
        return null;
    }
    })


    return (
        <div>
            <ContentHeader title="Mountain Ranges" />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                    </Row>
                    <br />

                    <Row>
                        {ranges}
                    </Row>
                </div>
            </Content>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        global: state.global,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGlobal: () => dispatch(fetchGlobal()),
        setLogout: (key) => dispatch(setLogout(key)),
        changeMontainRangeSpringCondtion: (obj) => dispatch(changeMontainRangeSpringCondtion(obj))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Mountainranges);
