import React from 'react'
import { Col, Row, Button, Input, Radio, message } from 'antd';

import { connect } from 'react-redux'
import { setMoreDetails } from '../../redux'
const { TextArea } = Input;

function MoreDeatils({ setMoreDetails, newReport, next, prev, global, img_type }) {

    const img_path = (img_type === "edit") ? "../images/" : "./images/";

    function submit(e) {
        e.preventDefault();

        if (step1Validation()) {
            next();
        }
    }

    function step1Validation() {
        let formIsValid = true;

        // if (!newReport.moreDetails.hazardSummary) {
        //     formIsValid = false;
        //     message.error('Please add hazard summary.');
        //     return formIsValid;
        // }

        // if (!newReport.moreDetails.sonwpackSummary) {
        //     formIsValid = false;
        //     message.error('Please add sonwpack summary.');
        //     return formIsValid;
        // }

        // if (!newReport.moreDetails.wheatherSummary) {
        //     formIsValid = false;
        //     message.error('Please add wheather summary.');
        //     return formIsValid;
        // }

        if (!newReport.moreDetails.forcastConfidence) {
            formIsValid = false;
            message.error('Please select a forcast confidence.');
            return formIsValid;
        }

        return formIsValid;
    }

    function previous(e) {
        if (newReport.primaryHazard.hazardId === 0) {
            prev(2)
        } else {
            prev(3)
        }
    }

    // const predictions = global.predictions.map(function (item, i) {
    //     return (
    //         <div key={i} style={{ float: "left", marginRight: 40, }}>
    //             <center>
    //                 <label>
    //                     <img src={img_path + item.image} alt={item.name} style={{width: 70}} />
    //                     <br />
    //                     <Radio value={item.id}></Radio>
    //                 </label>
    //             </center>
    //         </div>
    //     )
    // })

    return (
        <div>
            <Row>
                <Col span={24}>
                    <h2>More Details</h2>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Hazard summary</label>
                    <br />
                    <TextArea className="text-area-" rows={4} onChange={e => setMoreDetails({ ...newReport.moreDetails, hazardSummary: e.target.value })} value={newReport.moreDetails.hazardSummary} style={{ marginTop: 5 }} />
                </Col>
            </Row>
            <br />
            <Row style={{ marginTop: 10 }}>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Snowpack summary</label>
                    <br />
                    <TextArea className="text-area-" rows={4} onChange={e => setMoreDetails({ ...newReport.moreDetails, sonwpackSummary: e.target.value })} value={newReport.moreDetails.sonwpackSummary} style={{ marginTop: 5 }} />
                </Col>
            </Row>
            <br />
            <Row style={{ marginTop: 10 }}>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Weather summary </label>
                    <br />
                    <TextArea className="text-area-" rows={4} onChange={e => setMoreDetails({ ...newReport.moreDetails, wheatherSummary: e.target.value })} value={newReport.moreDetails.wheatherSummary} style={{ marginTop: 5 }} />
                </Col>
            </Row>
            <br />
            <Row style={{ marginTop: 10 }}>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Forecast confidence</label>
                    <br />
                    <Radio.Group onChange={e => setMoreDetails({ ...newReport.moreDetails, forcastConfidence: e.target.value })} value={newReport.moreDetails.forcastConfidence} style={{ marginTop: 5 }}>
                        <Radio value={"High"}>High</Radio>
                        <Radio value={"Moderate"}>Moderate</Radio>
                        <Radio value={"Low"}>Low</Radio>
                    </Radio.Group>
                </Col>
                <Col md={24} sm={24}>
                    <br />
                    <TextArea className="text-area-" rows={4} onChange={e => setMoreDetails({ ...newReport.moreDetails, forcastConfidenceSummary: e.target.value })} value={newReport.moreDetails.forcastConfidenceSummary} />
                </Col>
            </Row>
            <br />
            {/* below changes need to talk with cutomer before push to the server */}
            {/* <Row style={{ marginTop: 10 }}>
                <Col md={12} sm={12}>
                    <label className="report-form-lbl">Yesterday Status</label>
                    <br />
                    <Radio.Group style={{ marginTop: 5 }}  onChange={e => setMoreDetails({ ...newReport.moreDetails, yesterDayStatus: e.target.value })} value={newReport.moreDetails.yesterDayStatus}>
                        {predictions}
                    </Radio.Group>
                </Col>
                <Col md={12} sm={12}>
                    <label className="report-form-lbl">Tomorrow Status</label>
                    <br />
                    <Radio.Group style={{ marginTop: 5 }} onChange={e => setMoreDetails({ ...newReport.moreDetails, tomorrowStatus: e.target.value })} value={newReport.moreDetails.tomorrowStatus}>
                        {predictions}
                    </Radio.Group>
                </Col>

                <Col md={12} sm={12} style={{ marginTop: 30 }} >
                    <label className="report-form-lbl">Day After Tomorrow Status</label>
                    <br />
                    <Radio.Group style={{ marginTop: 5 }} onChange={e => setMoreDetails({ ...newReport.moreDetails, dayAfterTmorrowStatus: e.target.value })} value={newReport.moreDetails.dayAfterTmorrowStatus}>
                        {predictions}
                    </Radio.Group>
                </Col>
            </Row>
            <br /> */}


            <Row style={{ marginTop: 10 }}>
                <Col md={24} sm={24}>
                    <Button className="report-form-back-btn" onClick={e => previous(3)}>Back</Button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button className="report-form-next-btn" type="primary" onClick={e => submit(e)}>Save</Button>
                </Col>
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        newReport: state.newReport,
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMoreDetails: (obj) => dispatch(setMoreDetails(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDeatils);
