import React from 'react'
import { Col, DatePicker, Input, Row, Button, Radio, message, } from 'antd';
import moment from 'moment'
import { connect } from 'react-redux'
import { setReportDate, setRegionalOutlook, setMountainRegion, setNewReportLoading, setLogout, setReportCreateData } from '../../redux'
// import axios from 'axios'
// import { apiBaseUrl } from '../../Url'

const { TextArea } = Input;

function Summery({ setReportDate, newReport, setRegionalOutlook, setStep, global, setMountainRegion, setNewReportLoading, setLogout, setReportCreateData }) {

    function onChange(e, dateString) {
        // setReportDate(dateString)
        console.log(dateString, "lol");
        const date = moment.utc(new Date(e)).local().format('YYYY/MM/DD');
        setReportDate(date);
    }

    const montainRangesRadios = global.montainRanges && global.montainRanges.map(function (item, i) {
            if(item.id === 1){
                return null
            }
            return (
                <div key={i}>
                    <Radio value={item.id}>{item.name}</Radio>
                    <br />
                </div>
            )
    })

    function submit(e) {
        e.preventDefault();

        if (step1Validation()) {
            setStep(2);
        }
    }

    function step1Validation() {
        let formIsValid = true;

        if (!newReport.date) {
            formIsValid = false;
            message.error('Please select a date.');
            return formIsValid;
        }

        if (!newReport.montainRegion) {
            formIsValid = false;
            message.error('Please select a montain region.');
            return formIsValid;
        }

        // if (!newReport.regionalOutlook) {
        //     formIsValid = false;
        //     message.error('Please enter regional outlook.');
        //     return formIsValid;
        // }

        return formIsValid;
    }


    // new changes appy here
    function onMuntainRangeChange(id) {
        setMountainRegion(id)

        //// below change need to double check with customer

        // setNewReportLoading(true)
        // axios(
        //     {
        //         method: 'POST',
        //         url: apiBaseUrl + "report/getLatestByRegion",
        //         headers: { auth_key: localStorage.getItem('sessionKey') },
        //         data: { regionId: id }
        //     })
        //     .then(response => {
        //         // console.log(response)

        //         if (response.data.status === 401) {
        //             setLogout(localStorage.getItem('sessionKey'))
        //         } else if (response.data.status === 1) {
        //             setReportCreateData(response.data)
        //         } else {
        //             showError(response.data.message)
        //         }
        //         setNewReportLoading(false)
        //     })
        //     .catch(error => {
        //         const errorMsg = error.message
        //         showError(errorMsg)
        //         setNewReportLoading(false)
        //         console.log(errorMsg)
        //     })
    }

    // function showError(msg) {
    //     Modal.error({
    //         content: msg,
    //     });
    // }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <h2>Summary</h2>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Date</label>
                    <br />
                    <DatePicker onChange={onChange} value={moment(newReport.date, 'YYYY/MM/DD')} format={'YYYY/MM/DD'} style={{ marginTop: 5 }} className="report-form-datepicker" />
                </Col>
            </Row>
            <br />
            <Row style={{ marginTop: 10 }}>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Region</label>
                    <br />
                    <Radio.Group onChange={e => onMuntainRangeChange(e.target.value)} value={newReport.montainRegion} style={{ marginTop: 5 }}>
                        {montainRangesRadios}
                    </Radio.Group>
                </Col>
            </Row>
            <br />
            <Row style={{ marginTop: 10 }}>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Regional outlook</label>
                    <br />
                    <TextArea className="text-area-" rows={4} onChange={e => setRegionalOutlook(e.target.value)} value={newReport.regionalOutlook} style={{ marginTop: 5 }} />
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={24} sm={24}>
                    <Button className="report-form-next-btn" type="primary" onClick={e => submit(e)}>Next</Button>
                </Col>
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        newReport: state.newReport,
        global: state.global
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportDate: (date) => dispatch(setReportDate(date)),
        setRegionalOutlook: (str) => dispatch(setRegionalOutlook(str)),
        setMountainRegion: (str) => dispatch(setMountainRegion(str)),
        setNewReportLoading: (bool) => dispatch(setNewReportLoading(bool)),
        setLogout: (key) => dispatch(setLogout(key)),
        setReportCreateData: (obj) => dispatch(setReportCreateData(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Summery);
