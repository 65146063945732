import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Radio, Button, Modal } from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { setReportLoading, setReports, fetchGlobal } from './redux'
import Loader from './components/Loader';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import { apiBaseUrl } from './Url'
import Titledetails from './components/report-details/TitleDetails';
import DangerRatings from './components/report-details/DangerRatings';
import HazardsDetails from './components/report-details/HazardsDetails';
import MoreDetails from './components/report-details/MoreDetails';

const { confirm } = Modal;
const { Content } = Layout;

function Reports({ setReports, setReportLoading, report, history, match, fetchGlobal, user }) {
    const [type, setType] = useState("Danger Rating")

    const img_path = "../images/";

    useEffect(() => {

        if (!user.details.type) {
            history.push('/login')
        }

    }, [user.details.type, history]);

    useEffect(() => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/get_view_data",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: match.params.id }
            })
            .then(response => {
                console.log(response)
                if (response.data.status !== 0) {
                    setReports(response.data.reports)
                } else {
                    history.push('/reports')
                }
                setReportLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
                // showError(errorMsg)
            })

    }, [match.params.id, setReportLoading, setReports, history]);

    const confirmPublish = (e) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Are You Sure?",
            onOk() {
                publishReport();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const publishReport = () => {
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/publish",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id: match.params.id }
            })
            .then(response => {
                fetchGlobal()
                setReportLoading(false)
                history.push('/reports')
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
            })
    }


    return (
        <div>
            <ContentHeader title={(user.details.type === "publisher" || user.details.type === "admin") ? "View / Publish Report" : "View Report"} />

            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>
                        <Col md={5} sm={12}>
                        </Col>
                        {(report.reports.length > 0)
                            ?
                            <Col md={14} sm={12}>
                                <center>
                                    <div className="report-container" >
                                        <Titledetails
                                            region={report.reports[0].report.region}
                                            created_by={report.reports[0].report.created_by}
                                            published_by={report.reports[0].report.published_by}
                                            regional_outlook={report.reports[0].report.regional_outlook}
                                            date={report.reports[0].report.date}
                                            region_img={report.reports[0].report.region_img}
                                            img_path={img_path}
                                            forecast_confidence={report.reports[0].report.forecast_confidence}
                                        />
                                        <br />
                                        <div style={{ paddingLeft: 5, paddingRight: 5 }}>

                                            <center>
                                                <Radio.Group onChange={e => setType(e.target.value)} value={type} buttonStyle="solid" size="large" >
                                                    <Radio.Button value="Danger Rating"><center>Danger Rating</center></Radio.Button>
                                                    <Radio.Button value="Hazards"><center>Hazards</center></Radio.Button>
                                                    <Radio.Button value="Details"><center>Details</center></Radio.Button>
                                                </Radio.Group>
                                            </center>
                                        </div>
                                        <br />
                                        {(type === "Danger Rating")
                                            ?
                                            <DangerRatings
                                                alpine_hazards={report.reports[0].alpine_hazards}
                                                override_alpine_rating={report.reports[0].report.override_alpine_rating}
                                                override_sub_alpine_rating={report.reports[0].report.override_sub_alpine_rating}
                                                sub_alpine_hazards={report.reports[0].sub_alpine_hazards}
                                                travel_and_terrain_advice={report.reports[0].report.travel_and_terrain_advice}
                                                region={report.reports[0].report.region}
                                                img_path={img_path}
                                                date={report.reports[0].report.date}
                                                setType={setType}
                                            />
                                            : (type === "Hazards")
                                                ?
                                                <HazardsDetails
                                                    categorisation={report.reports[0].categorisation}
                                                    img_path={img_path}
                                                    setType={setType}
                                                />
                                                :
                                                <MoreDetails
                                                    hazard_summary={report.reports[0].report.hazard_summary}
                                                    snowpack_summary={report.reports[0].report.snowpack_summary}
                                                    weather_summary={report.reports[0].report.weather_summary}
                                                    forecast_confidence={report.reports[0].report.forecast_confidence}
                                                    forecast_confidence_summary={report.reports[0].report.forecast_confidence_summary}
                                                />
                                        }

                                        <div className="more-details-section" style={{ marginTop: 30 }}>
                                            <Row>
                                                {
                                                    report.reports[0].report.yesterday_status
                                                        ?
                                                        <Col md={8} sm={8}>
                                                            <center>
                                                                <img src={img_path + report.reports[0].report.yesterday_status} alt="spring conditions" style={{ width: 100 }} />
                                                                <br />
                                                                <span>Yesterday Status</span>
                                                            </center>
                                                        </Col>
                                                        : null
                                                }

                                                {
                                                    report.reports[0].report.tomorrow_status
                                                        ?
                                                        <Col md={8} sm={8}>
                                                            <center>
                                                                <img src={img_path + report.reports[0].report.tomorrow_status} alt="spring conditions" style={{ width: 100 }} />
                                                                <br />
                                                                <span>Tomorrow Status</span>
                                                            </center>
                                                        </Col>
                                                        : null
                                                }

                                                {
                                                    report.reports[0].report.day_after_tomorrow_status
                                                        ?
                                                        <Col md={8} sm={8}>
                                                            <center>
                                                                <img src={img_path + report.reports[0].report.day_after_tomorrow_status} alt="spring conditions" style={{ width: 100 }} />
                                                                <br />
                                                                <span>Day After Tomorrow Status</span>
                                                            </center>
                                                        </Col>
                                                        : null
                                                }
                                            </Row>

                                        </div>
                                    </div>
                                </center>
                                {
                                    (report.reports[0].report.status === "Un-Published" && (user.details.type === "publisher" || user.details.type === "admin"))
                                        ?
                                        <Row style={{ marginTop: 20 }}>
                                            <Button type="primary" onClick={e => confirmPublish(e)}>Publish</Button>
                                        </Row>
                                        :
                                        null
                                }
                            </Col>
                            :
                            null
                        }
                    </Row>
                </div>
                {(report.loading ? <Loader title="Loading..." /> : null)}
            </Content>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        report: state.report,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportLoading: (bool) => dispatch(setReportLoading(bool)),
        setReports: (obj) => dispatch(setReports(obj)),
        fetchGlobal: () => dispatch(fetchGlobal()),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reports);
