import React from 'react'
import { Table, Button, Tooltip, Modal } from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import moment from 'moment'
import axios from 'axios'
import { apiBaseUrl } from '../../Url'

const { confirm } = Modal;

function Reportlist({ data, user, setReportLoading, fetchGlobal, history }) {

    const columns = [
        {
            title: 'Report Id',
            dataIndex: 'id',
            key: 'id',
            sorter: {
                compare: (a, b) => a.id - b.id
            },
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: {
                compare: (a, b) => moment(a.date) - moment(b.date)
            },
            render: (text, record) => (
                <span style={{ color: "#333333 " }}>{moment(new Date(record.date)).format("LL")}</span>
            ),
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
                <span style={{ color: "green !important" }}>{(record.status === "Un-Published") ? "Unpublished" : "Published"}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div key={record.id}>
                    {(user.details.type === 'admin')
                        ?
                        <div>
                            <Tooltip title="Edit Report" >
                                <Link to={"/edit-report/" + record.id}><Button type="primary" size="small" ><EditOutlined /></Button></Link>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title="Delete Report" >
                                <Button type="primary" danger size="small" onClick={e => deleteReportMsg(e, record.id)}><DeleteOutlined /></Button>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title={(user.details.type === 'admin') ? "View / Publish Report" : "View Report"}>
                                <Link to={"/view-and-publish-report/" + record.id}><Button className="green-button" size="small" ><EyeOutlined /></Button></Link>
                            </Tooltip>
                            &nbsp;
                            <Tooltip  title="Override the calculated outcome." >
                                <Link to={"/override-coutcome/" + record.id}><Button type="secondary" size="small" ><MenuUnfoldOutlined /></Button></Link>
                            </Tooltip>
                        </div>
                        :
                        <div>
                            <Tooltip title="Edit Report" >
                                <Link to={"/edit-report/" + record.id}><Button type="primary" size="small" ><EditOutlined /></Button></Link>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title="View / Publish Report" >
                                <Link to={"/view-and-publish-report/" + record.id}><Button className="green-button" size="small" ><EyeOutlined /></Button></Link>
                            </Tooltip>
                            &nbsp;
                            <Tooltip  title="Override the calculated outcome." >
                                <Link to={"/override-coutcome/" + record.id}><Button type="secondary" size="small" ><MenuUnfoldOutlined /></Button></Link>
                            </Tooltip>
                        </div>
                    }
                </div>
            ),
        },
    ];


    const deleteReportMsg = (e, id) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Are You Sure?",
            onOk() {
                deleteReport(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const deleteReport = (id) => {
        // alert(id)
        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/delete",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { id }
            })
            .then(response => {
                fetchGlobal()
                setReportLoading(false)
                history.push('/reports')
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
            })
    }

    return (
        <div className="list-bl">
            <Table columns={columns} dataSource={data} rowKey="id" />
        </div>
    )
}

export default Reportlist
