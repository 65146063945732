const initState = {
    loading: false,
    error: "",
    montainRanges: [],
    zones: [],
    hazards: [],
    avalancheSizes: [],
    hazardAspects: [],
    hazardCharacteristics: [],
    hazardElevation: [],
    hazardLikelihood: [],
    reports: [],
    users: [],
    predictions: [],
}

const globalReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_GLOBAL_REQUEST":
            return {
                ...state,
                loading: true
            }
        case "FETCH_GLOBAL_SUCCESS":
            return {
                ...state,
                loading: false,
                montainRanges: action.payload.montainRanges,
                zones: action.payload.zones,
                hazards: action.payload.hazards,
                avalancheSizes: action.payload.avalancheSizes,
                hazardAspects: action.payload.hazardAspects,
                hazardCharacteristics: action.payload.hazardCharacteristics,
                hazardElevation: action.payload.hazardElevation,
                hazardLikelihood: action.payload.hazardLikelihood,
                reports: action.payload.reports,
                users: action.payload.users,
                predictions: action.payload.predictions,
                error: ""
            }
        case "FETCH_GLOBAL_FALIUR":
            return {
                ...state,
                loading: false,
                error: action.payload,
                montainRanges: [],
                zones: [],
                hazards: [],
                avalancheSizes: [],
                hazardCharacteristics: [],
                hazardElevation: [],
                hazardAspects: [],
                hazardLikelihood: [],
                users: [],
                reports: [],
                predictions: [],
            }

        case "SET_GLOBAL_EMPTY":
            return {
                ...state,
                loading: false,
                error: "",
                montainRanges: [],
                zones: [],
                hazards: [],
                avalancheSizes: [],
                hazardCharacteristics: [],
                hazardElevation: [],
                hazardAspects: [],
                hazardLikelihood: [],
                users: [],
                reports: [],
                predictions: [],
            }

        case "CHANGE_MONTAIN_RANGE_SPRING_CONDTION":
            let newAray = [...state.montainRanges];
            let foundIndex1 = newAray.findIndex(x => x.id === action.payload.id);
            newAray[foundIndex1].spring_condition = action.payload.flag

            return {
                ...state,
                montainRanges: newAray,
                loading: false,
            }


        default:
            return state
    }

}

export default globalReducer;