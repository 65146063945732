import React, { useState } from 'react';
import { Layout, Row, Col, DatePicker } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment'


const { Content } = Layout;

function ViewSpringConditionsReport({ match, history }) {
    const [showRegionalOutlookCalander, set_showRegionalOutlookCalander] = useState(false)


    var sectionStyle = {
        backgroundImage: "url(../images/report-background.png)",
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "center center",
        backgroundSize: "100%"
    };

    const toggleRegionalOutlookClander = (e) => {
        e.preventDefault();
        if (showRegionalOutlookCalander) {
            set_showRegionalOutlookCalander(false)
        } else {
            set_showRegionalOutlookCalander(true)
        }
    }

    function onDateChange(e, region) {
        const date = moment(new Date(e)).format('YYYY-MM-DD')
        // console.log(date);

        if (region === "main-range") {
            window.location.replace('/main-range/' + date)
        }

        if (region === "dividing-range") {
            window.location.replace('/dividing-range/' + date)
        }

        if (region === "front-range") {
            window.location.replace('/front-range/' + date)
        }
    }





    return (
        <div>
            {/* <ContentHeader title="MSC HQ" /> */}
            <Content style={{ margin: '0px 0px 0' }} >
                <div className="site-layout-background reports-layout-background" style={{ padding: 0,  backgroundColor: "#eee" }} >
                    <Row>



                        <Col md={6} sm={12} style={sectionStyle}>
                        </Col>


                        <Col md={12} sm={24}>

                            <Row>
                                <Col md={24} sm={24} >
                                    <center>
                                        <div className="report-container" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <a href="https://mountainsafetycollective.org/">
                                                <img alt="header" src="../images/header.png" className="report-header-img" />
                                            </a>
                                        </div>
                                    </center>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={24} sm={24} >
                                    <center>
                                        <div className="report-container">
                                            <Row className="region-img-container" >
                                                {
                                                    (match.params.id === "dividing-range")
                                                        ?
                                                        <Col span="24" >
                                                            <img alt={'region'} src={'../images/diving_range.png'} className="main-img" />
                                                            <img alt={"ssss"} src={"../images/sponsor_diving_range.png"} className="sponser-img" />
                                                        </Col>
                                                        :
                                                        (match.params.id === "main-range")
                                                            ?
                                                            <Col span="24" >
                                                                <img alt={'region'} src={'../images/main_range.png'} className="main-img" />
                                                                <img alt={"ssss"} src={"../images/sponsor_main_range.png"} className="sponser-img" />
                                                            </Col>
                                                            :
                                                            <Col span="24" >
                                                                <img alt={'region'} src={'../images/front_range.png'} className="main-img" />
                                                                <img alt={"ssss"} src={"../images/sponsor_front_range.png"} className="sponser-img" />
                                                            </Col>
                                                }
                                            </Row>
                                        </div>
                                    </center>
                                </Col>
                            </Row>

                            <Row >
                                <Col md={24} sm={24} >
                                    <div className="report-container" style={{ padding: "20px 50px", minHeight: "30vh" }}>
                                        <p >
                                            Spring Conditions:  The hazards become quite variable and can range from low to high in any given day depending on the amount of refreeze. Timing is very important. Generally speaking, if you are too early and the surface crust is hard and icy, a significant slide hazard will exist. If you are too late, and the day warms up significantly, wet snow avalanches can become a concern. Lastly, even though it is spring, winter like storms can roll through and there can be the potential of wind slabs and new cornice development.
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row >
                                <Col md={24} sm={24} >
                                    <div className="report-container" style={{ padding: "20px 50px", minHeight: "50vh" }}>
                                        <center>
                                            <img alt="header" src="../images/spering-condition-static.png" className="spring-condition-img-statc" />
                                        </center>
                                    </div>
                                </Col>

                            </Row>

                            <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 50, }}>
                                <Row style={{ marginTop: 25}}>
                                    <div className="report-other-tab-btn" href="/" onClick={e => toggleRegionalOutlookClander(e)}>
                                        <label>Regional Outlook Archive</label>
                                        {
                                            (showRegionalOutlookCalander)
                                                ?
                                                <span><MinusOutlined /></span>
                                                :
                                                <span><PlusOutlined /></span>
                                        }
                                    </div>
                                </Row>
                                {
                                    (showRegionalOutlookCalander)
                                        ?
                                        <Row>
                                            <div className="regional-outlook-calander">
                                                <DatePicker onChange={e => onDateChange(e, match.params.id)}
                                                    // value={moment(date, 'YYYY/MM/DD')}
                                                    // defaultValue={moment(date, 'DD/MM/YYYY')} 
                                                    format={'DD/MM/YYYY'}
                                                    open={true} />
                                            </div>
                                        </Row>
                                        :
                                        null
                                }
                            </div>

                        </Col>

                        <Col md={6} sm={12} style={sectionStyle}>

                        </Col>
                    </Row>
                </div>
            </Content>
        </div>
    );
}



export default ViewSpringConditionsReport;
