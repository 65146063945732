import React, { useState, useEffect } from 'react'
// import { Col, Radio, Row, Button, Input, message } from 'antd';
import { Col, Radio, Row, Button, Input } from 'antd';
import { connect } from 'react-redux'
import { setAlpineHazards, setSubAlpineChange, setAlpineHazardEmpty, setSubAlpineHazardEmpty, setTravelAndTerrainAdvice } from '../../redux'
// import { PlusOutlined, RightOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function Summery({ global, newReport, setAlpineHazards, setSubAlpineChange, setAlpineHazardEmpty, setSubAlpineHazardEmpty, setTravelAndTerrainAdvice, setStep, prev, img_type }) {
    const [alipineWeather, setalipineWeather] = useState(0)
    const [alpineVisibility, setalpineVisibility] = useState(0)
    const [alpinesurface, setalpinesurface] = useState(0)
    const [alpinealvance, setalpinealvance] = useState(0)
    const [subAlipineWeather, setSubAlipineWeather] = useState(0)
    const [subAlpineVisibility, setSubAlpineVisibility] = useState(0)
    const [subAlpinesurface, setSubAlpinesurface] = useState(0)
    const [subAlpinealvance, setSubAlpinealvance] = useState(0)

    const img_path = (img_type === "edit") ? "../images/" : "./images/";

    useEffect(() => {
        newReport.alpineHazards.map(function (item) {
            // console.log(item.category);
            if (item.category === "Weather conditions") {
                // console.log(item.id);
                setalipineWeather(item.id)
            }

            if (item.category === "Visibility") {
                setalpineVisibility(item.id)
            }

            if (item.category === "Surface conditions") {
                setalpinesurface(item.id)
            }

            if (item.category === "Avalanche danger") {
                setalpinealvance(item.id)
            }

            return null;
        })

    }, [newReport.alpineHazards])

    useEffect(() => {
        newReport.subAlpineHazards.map(function (item) {
            // console.log(item.category);
            if (item.category === "Weather conditions") {
                // console.log(item.id);
                setSubAlipineWeather(item.id)
            }

            if (item.category === "Visibility") {
                setSubAlpineVisibility(item.id)
            }

            if (item.category === "Surface conditions") {
                setSubAlpinesurface(item.id)
            }

            if (item.category === "Avalanche danger") {
                setSubAlpinealvance(item.id)
            }

            return null;
        })

    }, [newReport.subAlpineHazards])

    const onAlpineChange = e => {

        let copy = global.hazards.filter(function (el) {
            return el.id === e.target.value
        });

        setAlpineHazards(copy[0]);
    };

    const onSubAlpineChange = e => {

        let copy = global.hazards.filter(function (el) {
            return el.id === e.target.value
        });

        setSubAlpineChange(copy[0]);
    };

    const alphineWeatherConditionsRadios = global.hazards.map(function (item, i) {
        if (item.category === "Weather conditions") {
            return (
                <div key={i} className="danger-rating-radios">
                    <Radio value={item.id}><img src={img_path + item.img} alt={item.name} className="hazard-img" /></Radio>
                    {/* <Button size="small" className="hazard-about-btn">about &nbsp;&nbsp;&nbsp;<PlusOutlined /></Button> */}
                    <br />
                </div>
            )
        }
        return null
    })

    const alphineVisibilityRadios = global.hazards.map(function (item, i) {
        if (item.category === "Visibility") {
            return (
                <div key={i} className="danger-rating-radios">
                    {/* <Radio value={item.id}>{item.name + " - " + item.rating}</Radio> */}
                    <Radio value={item.id}><img src={img_path + item.img} alt={item.name} className="hazard-img" /></Radio>
                    {/* <Button size="small" className="hazard-about-btn">about &nbsp;&nbsp;&nbsp;<PlusOutlined /></Button> */}
                    <br />
                </div>
            )
        }
        return null
    })

    const alphineSurfaceConditionsRadios = global.hazards.map(function (item, i) {
        if (item.category === "Surface conditions") {
            return (
                <div key={i} className="danger-rating-radios">
                    <Radio value={item.id}><img src={img_path + item.img} alt={item.name} className="hazard-img" /></Radio>

                    {/* {(item.name === "Widespread ice danger")
                        ?
                        <Button size="small" className="hazard-about-btn">find out more<RightOutlined /></Button>
                        :
                        <Button size="small" className="hazard-about-btn">about &nbsp;&nbsp;&nbsp;<PlusOutlined /></Button>

                    } */}
                    <br />
                </div>
            )
        }
        return null
    })

    const alphineAvalancheDangerRadios = global.hazards.map(function (item, i) {
        if (item.category === "Avalanche danger") {
            return (
                <div key={i} className="danger-rating-radios">
                    <Radio value={item.id}><img src={img_path + item.img} alt={item.name} className="hazard-img" /></Radio>
                    {/* {(item.name === "Moderate avalanche danger" || item.name === "Considerable avalanche danger" || item.name === "High avalanche danger")
                        ?
                        <Button size="small" className="hazard-about-btn">find out more<RightOutlined /></Button>
                        :
                        <Button size="small" className="hazard-about-btn">about &nbsp;&nbsp;&nbsp;<PlusOutlined /></Button>
                    } */}
                    <br />
                </div>
            )
        }
        return null
    })

    const resetAlpine = () => {
        setAlpineHazardEmpty()
        setalipineWeather(0)
        setalpineVisibility(0)
        setalpinesurface(0)
        setalpinealvance(0)
    }

    const resetSubAlpine = () => {
        setSubAlpineHazardEmpty()
        setSubAlipineWeather(0)
        setSubAlpineVisibility(0)
        setSubAlpinesurface(0)
        setSubAlpinealvance(0)
    }


    function submit(e) {
        e.preventDefault();

        if (step2Validation()) {
            setStep(3);
        }
    }

    function step2Validation() {
        let formIsValid = true;

        // if (newReport.alpineHazards.length < 1) {
        //     formIsValid = false;
        //     message.error('Select minimum one hazard for alpine.');
        //     return formIsValid;
        // }

        // if (newReport.subAlpineHazards.length < 1) {
        //     formIsValid = false;
        //     message.error('Select minimum one hazard for sub alpine.');
        //     return formIsValid;
        // }

        // if (!newReport.travelAndTerrainAdvice) {
        //     formIsValid = false;
        //     message.error('Please add travel and terrain advice.');
        //     return formIsValid;
        // }

        return formIsValid;
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <h2>Danger rating</h2>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={24}>
                            <h3>Hazard Alpine &nbsp;&nbsp;&nbsp;<Button className="danger-rating-reset" size="small" onClick={resetAlpine}>Reset</Button></h3>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Weather conditions</label>
                            <br />
                            <Radio.Group onChange={onAlpineChange} value={alipineWeather} style={{marginTop: 5}}>
                                {alphineWeatherConditionsRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{marginTop: 10}}>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Visibility</label>
                            <br />
                            <Radio.Group onChange={onAlpineChange} value={alpineVisibility} style={{marginTop: 5}}>
                                {alphineVisibilityRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{marginTop: 10}}>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Surface conditions</label>
                            <br />
                            <Radio.Group onChange={onAlpineChange} value={alpinesurface} style={{marginTop: 5}}>
                                {alphineSurfaceConditionsRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{marginTop: 10}}>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Avalanche danger</label>
                            <br />
                            <Radio.Group onChange={onAlpineChange} value={alpinealvance} style={{marginTop: 5}}>
                                {alphineAvalancheDangerRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                </Col>

                {/* --------------------------------------------------------------------------------------------------------------------------- */}

                <Col md={12}>
                    <Row>
                        <Col md={24}>
                            <h3>Hazard Subalpine &nbsp;&nbsp;&nbsp;<Button className="danger-rating-reset" size="small" onClick={resetSubAlpine}>Reset</Button></h3>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Weather conditions</label>
                            <br />
                            <Radio.Group onChange={onSubAlpineChange} value={subAlipineWeather} style={{marginTop: 5}}>
                                {alphineWeatherConditionsRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{marginTop: 10}}>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Visibility</label>
                            <br />
                            <Radio.Group onChange={onSubAlpineChange} value={subAlpineVisibility} style={{marginTop: 5}}>
                                {alphineVisibilityRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{marginTop: 10}}>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Surface conditions</label>
                            <br />
                            <Radio.Group onChange={onSubAlpineChange} value={subAlpinesurface} style={{marginTop: 5}}>
                                {alphineSurfaceConditionsRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{marginTop: 10}}>
                        <Col md={24}>
                            <label className="danger-rating-group-title">Avalanche danger</label>
                            <br />
                            <Radio.Group onChange={onSubAlpineChange} value={subAlpinealvance} style={{marginTop: 5}}>
                                {alphineAvalancheDangerRadios}
                            </Radio.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <Row style={{marginTop: 10}}>
                <Col md={24} sm={24}>
                    <label className="report-form-lbl">Travel and terrain advice</label>
                    <br />
                    <TextArea rows={4} className="text-area-" onChange={e => setTravelAndTerrainAdvice(e.target.value)} value={newReport.travelAndTerrainAdvice} style={{marginTop: 5}}/>
                </Col>
            </Row>
            <br />
            <Row style={{marginTop: 10}}>
                <Col md={24} sm={24}>
                    <Button className="report-form-back-btn" onClick={e => prev(1)}>Back</Button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button className="report-form-next-btn" type="primary" onClick={e => submit(e)}>Next</Button>
                </Col>
            </Row>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        newReport: state.newReport,
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAlpineHazards: (obj) => dispatch(setAlpineHazards(obj)),
        setSubAlpineChange: (obj) => dispatch(setSubAlpineChange(obj)),
        setTravelAndTerrainAdvice: (str) => dispatch(setTravelAndTerrainAdvice(str)),
        setAlpineHazardEmpty: () => dispatch(setAlpineHazardEmpty()),
        setSubAlpineHazardEmpty: () => dispatch(setSubAlpineHazardEmpty()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Summery);
