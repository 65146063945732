import React, { useEffect, useState } from 'react'
import { Col, Radio } from 'antd';

import Titledetails from './report-details/TitleDetails';
import DangerRatings from './report-details/DangerRatings';
import HazardsDetails from './report-details/HazardsDetails';
import MoreDetails from './report-details/MoreDetails';


function Report({ item }) {
    const [type, setType] = useState("Danger Rating")

    useEffect(() => {
        setType("Danger Rating")
    }, [item])

    const img_path = "../images/";

    return (
        <Col md={24} sm={24} >
            <center>
                <div className="report-container">
                    <Titledetails
                        region={item.report.region}
                        created_by={item.report.created_by}
                        published_by={item.report.published_by}
                        regional_outlook={item.report.regional_outlook}
                        date={item.report.date}
                        region_img={item.report.region_img}
                        forecast_confidence={item.report.forecast_confidence}
                        img_path={img_path}
                    />
                    <br />
                    <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <center>
                            <Radio.Group onChange={e => setType(e.target.value)} value={type} buttonStyle="solid" size="large" >
                                <Radio.Button value="Danger Rating"><center>Danger Rating</center></Radio.Button>
                                <Radio.Button value="Hazards"><center>Hazards</center></Radio.Button>
                                <Radio.Button value="Details"><center>Details</center></Radio.Button>
                            </Radio.Group>
                        </center>
                    </div>
                    <br />
                    {(type === "Danger Rating")
                        ?
                        <DangerRatings
                            alpine_hazards={item.alpine_hazards}
                            sub_alpine_hazards={item.sub_alpine_hazards}
                            travel_and_terrain_advice={item.report.travel_and_terrain_advice}
                            override_alpine_rating={item.report.override_alpine_rating}
                            override_sub_alpine_rating={item.report.override_sub_alpine_rating}
                            region={item.report.region}
                            img_path={img_path}
                            setType={setType}
                            date={item.report.date}
                        />
                        : (type === "Hazards")
                            ?
                            <HazardsDetails
                                categorisation={item.categorisation}
                                img_path={img_path}
                                setType={setType}
                            />
                            :
                            <MoreDetails
                                hazard_summary={item.report.hazard_summary}
                                snowpack_summary={item.report.snowpack_summary}
                                weather_summary={item.report.weather_summary}
                                forecast_confidence={item.report.forecast_confidence}
                                forecast_confidence_summary={item.report.forecast_confidence_summary}
                            />
                    }
                </div>
            </center>
        </Col>
    )
}

export default Report
