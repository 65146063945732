export const setReportLoading = (bool) => {
    return {
        type: "SET_REPORT_LOADING",
        payload: bool
    }
}

export const setReports = (obj) => {
    return {
        type: "SET_REPORTS",
        payload: obj
    }
}