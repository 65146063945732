import moment from 'moment'

const initState = {
    loading: false,
    error: "",
    // date: moment().format('YYYY/MM/DD'),
    date: moment.utc(new Date()).local().format('YYYY/MM/DD'),
    // date: "2012/12/12",
    montainRegion: 0,
    regionalOutlook: "",
    alpineHazards: [],
    subAlpineHazards: [],
    travelAndTerrainAdvice: "",
    primaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
    secondaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
    tertiaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
    quaternaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
    moreDetails: { hazardSummary: "", sonwpackSummary: "", wheatherSummary: "", forcastConfidence: "", forcastConfidenceSummary: "", yesterDayStatus: 0, tomorrowStatus: 0, dayAfterTmorrowStatus: 0 }
}


const newReportReducer = (state = initState, action) => {
    switch (action.type) {

        case "SET_NEW_REPORT_LOADING_TRUE":
            return {
                ...state,
                loading: action.payload
            }

        case "SET_REPORT_DATE":
            return {
                ...state,
                date: action.payload
            }

        case "SET_MONTAIN_REGION":
            return {
                ...state,
                montainRegion: action.payload
            }

        case "SET_REGIONAL_OUTLOOK":
            return {
                ...state,
                regionalOutlook: action.payload
            }

        case "SET_ALPINE_HAZARDS":
            let newAray = [...state.alpineHazards];
            for (var i = 0; i < newAray.length; i++) {
                if (newAray[i].category === action.payload.category) {
                    newAray.splice(i, 1);
                    break;
                }
            }

            if (newAray.length < 4) {
                newAray.push(action.payload)
            }

            return {
                ...state,
                alpineHazards: newAray
            }

        case "SET_SUB_ALPINE_HAZARDS":
            let newAray1 = [...state.subAlpineHazards];
            for (i = 0; i < newAray1.length; i++) {
                if (newAray1[i].category === action.payload.category) {
                    newAray1.splice(i, 1);
                    break;
                }
            }

            if (newAray1.length < 4) {
                newAray1.push(action.payload)
            }

            return {
                ...state,
                subAlpineHazards: newAray1
            }

        case "SET_ALPINE_HAZARDS_EMPTY":
            return {
                ...state,
                alpineHazards: [],
                primaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                secondaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                tertiaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                quaternaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
            }

        case "SET_SUB_ALPINE_HAZARDS_EMPTY":
            return {
                ...state,
                subAlpineHazards: [],
                primaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                secondaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                tertiaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                quaternaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
            }

        case "SET_TRAVEL_AND_TERRAIN_ADVICE":
            return {
                ...state,
                travelAndTerrainAdvice: action.payload
            }

        case "SET_PRIMARY_HAZARD":
            return {
                ...state,
                primaryHazard: action.payload
            }

        case "SET_SECONDARY_HAZARD":
            return {
                ...state,
                secondaryHazard: action.payload
            }

        case "SET_TERTIARY_HAZARD":
            return {
                ...state,
                tertiaryHazard: action.payload
            }

        case "SET_QUATERNARY_HAZARD":
            return {
                ...state,
                quaternaryHazard: action.payload
            }

        case "SET_MORE_DETAILS":
            return {
                ...state,
                moreDetails: action.payload
            }

        case "SET_NEW_REPORT_EMPTY":
            return {
                ...state,
                loading: false,
                error: "",
                date: moment.utc(new Date()).local().format('YYYY/MM/DD'),
                montainRegion: 0,
                regionalOutlook: "",
                alpineHazards: [],
                subAlpineHazards: [],
                travelAndTerrainAdvice: "",
                primaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                secondaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                tertiaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                quaternaryHazard: { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                moreDetails: { hazardSummary: "", sonwpackSummary: "", wheatherSummary: "", forcastConfidence: "", forcastConfidenceSummary: "", yesterDayStatus: 0, tomorrowStatus: 0, dayAfterTmorrowStatus: 0 }
            }

        case "SET_REPORT_EDIT_DATA":
            return {
                ...state,
                date: moment(new Date(action.payload.report[0].date)).format("YYYY/MM/DD"),
                montainRegion: action.payload.report[0].montainRegion,
                regionalOutlook: action.payload.report[0].regionalOutlook,
                travelAndTerrainAdvice: action.payload.report[0].travelAndTerrainAdvice,
                alpineHazards: action.payload.danger_rating_alpine,
                subAlpineHazards: action.payload.danger_rating_sub_alpine,
                primaryHazard: (action.payload.primary_hazards.length > 0) ? action.payload.primary_hazards[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                secondaryHazard: (action.payload.secondary_hazards.length > 0) ? action.payload.secondary_hazards[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                tertiaryHazard: (action.payload.tertiary_hazards.length > 0) ? action.payload.tertiary_hazards[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                quaternaryHazard: (action.payload.quaternary_hazard.length > 0) ? action.payload.quaternary_hazard[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                moreDetails: (action.payload.more_details.length > 0) ? action.payload.more_details[0] : { hazardSummary: "", sonwpackSummary: "", wheatherSummary: "", forcastConfidence: "", forcastConfidenceSummary: "", yesterDayStatus: 0, tomorrowStatus: 0, dayAfterTmorrowStatus: 0 },
            }

        case "SET_REPORT_CREATE_DATA":
            return {
                ...state,
                montainRegion: action.payload.report[0].montainRegion,
                regionalOutlook: action.payload.report[0].regionalOutlook,
                travelAndTerrainAdvice: action.payload.report[0].travelAndTerrainAdvice,
                alpineHazards: action.payload.danger_rating_alpine,
                subAlpineHazards: action.payload.danger_rating_sub_alpine,
                primaryHazard: (action.payload.primary_hazards.length > 0) ? action.payload.primary_hazards[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                secondaryHazard: (action.payload.secondary_hazards.length > 0) ? action.payload.secondary_hazards[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                tertiaryHazard: (action.payload.tertiary_hazards.length > 0) ? action.payload.tertiary_hazards[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                quaternaryHazard: (action.payload.quaternary_hazard.length > 0) ? action.payload.quaternary_hazard[0] : { hazardId: 0, characteristicId: 0, elevationId: 0, aspectId: 0, avalancheSizesId: 0, likelihoodId: 0, summary: '' },
                moreDetails: (action.payload.more_details.length > 0) ? action.payload.more_details[0] : { hazardSummary: "", sonwpackSummary: "", wheatherSummary: "", forcastConfidence: "", forcastConfidenceSummary: "", yesterDayStatus: 0, tomorrowStatus: 0, dayAfterTmorrowStatus: 0 },
            }


        default:
            return state
    }

}

export default newReportReducer;