import React, { useEffect, useState } from 'react'
import { Layout } from 'antd';
import { Input, Button, Spin, message } from 'antd';
import { Row, Col } from 'antd';

import { connect } from 'react-redux'
import { fetchUserSuccess, fetchUserFaliur, setLogout, setUserLoading } from '../../redux'
import ContentHeader from '../../components/ContentHeader';

import axios from 'axios'
import { apiBaseUrl } from '../../Url'

const { Content } = Layout;

function EditBasic({ fetchUserSuccess, fetchUserFaliur, setUserLoading, user, history, setLogout }) {

    const [fname, setFname] = useState("")
    const [lname, seLname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    useEffect(() => {

        if (!user.details.email) {
            history.push("/login")
        } else {
            setFname(user.details.fname)
            seLname(user.details.lname)
            setEmail(user.details.email)
            setPhone(user.details.phone)
        }

    }, [user, history]);

    function handleLogin(e) {
        e.preventDefault();

        if (handleValidation()) {
            register();
        }
    }

    function handleValidation() {
        let formIsValid = true;

        if (!fname) {
            formIsValid = false;
            message.error('Please insert your first name.');
            return formIsValid;
        }

        if (!lname) {
            formIsValid = false;
            message.error('Please insert your last name.');
            return formIsValid;
        }


        //email
        if (!email) {
            formIsValid = false;
            message.error('Please insert your email address.');
            return formIsValid;
        }

        if (typeof email !== "undefined") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                message.error("Email is not valid.");
                return formIsValid;
            }
        }


        return formIsValid;
    }

    function register() {
        setUserLoading(true)
        const obj = { fname, lname, email, phone }
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "user/edit-basic-details",
                headers: { auth_key: localStorage.getItem('sessionKey') },
                data: { obj }
            })
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    const user = {
                        "fname": response.data.fname,
                        "lname": response.data.lname,
                        "email": response.data.email,
                        "phone": response.data.phone,
                        "type": response.data.type,
                    }
                    fetchUserSuccess(user)
                    message.success("Basic details updated successfully.")
                } else if (response.data.status === 401) {
                    setLogout(localStorage.getItem('sessionKey'))
                }
                else {
                    message.error(response.data.message)
                    fetchUserFaliur(response.data.message)
                }
            })
            .catch(error => {
                const errorMsg = error.message
                message.error(errorMsg)
                fetchUserFaliur(errorMsg)
            })

    }

    return (
        <div>
            <ContentHeader title="Edit Basic Details" />
            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" >
                    <Row>
                        <Col md={2} sm={24}></Col>
                        <Col md={20} sm={24}>
                            <form onSubmit={(e) => handleLogin(e)}>
                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>First Name</label>
                                        <Input type="text" placeholder="First Name" onChange={(e) => setFname(e.target.value)} value={fname} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Last Name</label>
                                        <Input type="text" placeholder="Last Name" onChange={(e) => seLname(e.target.value)} value={lname} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Email Address</label>
                                        <Input type="text" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </Col>
                                    <Col md={12} sm={24} className="padding-5">
                                        <label>Phone Number</label>
                                        <Input type="text" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                    </Col>
                                </Row>
                                <br />

                                <Row>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit">Edit</Button>
                                        {user.loading ? <>&nbsp;<Spin size="large" /> </> : null}
                                    </Col>
                                </Row>
                                <br />
                            </form>
                        </Col>
                        <Col md={2} sm={24}></Col>
                    </Row>
                </div>
            </Content>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserSuccess: (obj) => dispatch(fetchUserSuccess(obj)),
        setUserLoading: (bool) => dispatch(setUserLoading(bool)),
        fetchUserFaliur: (msg) => dispatch(fetchUserFaliur(msg)),
        setLogout: () => dispatch(setLogout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBasic);
